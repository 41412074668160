import { Component, OnInit } from '@angular/core';
import { smoothlyMenu } from '../../../app.helpers';
import { AppService } from 'app/services/app.service';
import { Router } from '@angular/router';
import { AppI18nService } from 'app/services/app.i18n.service';
import { LoginService } from "../../../services/login.service";
import { SpinnerService } from 'app/services/spinner.service';
import { APIKeysService } from 'app/services/keys.service';
declare var jQuery: any;

@Component({
  selector: 'app-topnavigationnavbar',
  templateUrl: 'topnavigationnavbar.template.html'
})
export class TopNavigationNavbarComponent implements OnInit{
  ngOnInit(): void {
    this.spinner.activate();
    this.keysService.moduleLink().subscribe(x => {
      this.spinner.desactivate();
      
      this.link = x;
    })
  }

  public link: string = "";

  constructor(public appService: AppService, public loginService: LoginService, private router: Router, public i18n: AppI18nService, private spinner: SpinnerService, private keysService: APIKeysService){

  }



  activeRoute( routeName: string ): boolean {
    return this.router.url.indexOf( routeName ) > -1;
  }

  toggleNavigation(): void {
    jQuery('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }

}
