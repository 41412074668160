<div class="row border-bottom white-bg">
  <nav class="navbar navbar-static-top" role="navigation">
    <div class="navbar-header">
      <button aria-controls="navbar" aria-expanded="false" data-target="#navbar" data-toggle="collapse"
        class="navbar-toggle collapsed" type="button">
        <i class="fas fa-bars"></i>
      </button>
      <a href="#" class="navbar-brand">{{appService.productName}}</a>
    </div>
    <div class="navbar-collapse collapse" id="navbar">
      <ul class="nav navbar-nav">
        <li [ngClass]="{active: activeRoute('/weather/keys/')}">
          <a aria-expanded="false" role="button" [routerLink]="['/weather/keys']"> Home</a>
        </li>
        <li [ngClass]="{active: activeRoute('/weather/howto/')}">
          <a aria-expanded="false" role="button" [routerLink]="['/weather/howto']"> NWeather How-To Guide</a>
        </li>
        <li [ngClass]="{active: activeRoute('/weather/darksky/')}">
          <a aria-expanded="false" role="button" [routerLink]="['/weather/darksky']"> Dark Sky Keys </a>
        </li>
        <li *ngIf="link != ''">
          <a aria-expanded="false" role="button" href="{{link}}" target="_blank"> Download N4 Weather Module</a>
        </li>
        <li [ngClass]="{active: activeRoute('/weather/contact/')}">
          <a aria-expanded="false" role="button" [routerLink]="['/weather/contact']"> Contact us</a>
        </li>
      </ul>
      <!-- <ul class="nav navbar-nav">
        <li class="active">
          <a aria-expanded="false" role="button" [routerLink]="['/layoutsview']"> Back to main Layout page</a>
        </li>
        <li dropdown>
          <a aria-expanded="false" role="button"  dropdownToggle> Menu item <span class="caret"></span></a>
          <ul role="menu" *dropdownMenu class="dropdown-menu">
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
          </ul>
        </li>
        <li dropdown>
          <a aria-expanded="false" role="button" dropdownToggle> Menu item <span class="caret"></span></a>
          <ul role="menu" *dropdownMenu class="dropdown-menu">
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
          </ul>
        </li>
        <li dropdown>
          <a aria-expanded="false" role="button" dropdownToggle> Menu item <span class="caret"></span></a>
          <ul role="menu" *dropdownMenu class="dropdown-menu">
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
          </ul>
        </li>
        <li dropdown>
          <a aria-expanded="false" role="button" dropdownToggle> Menu item <span class="caret"></span></a>
          <ul role="menu" *dropdownMenu class="dropdown-menu">
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
          </ul>
        </li>
      </ul> -->
      <ul class="nav navbar-top-links navbar-right">
        <li>
          <a [routerLink]="['profile/']">
            {{loginService.userInfo.name}}
          </a>
        </li>
        <li>
          <a (click)="loginService.logout()">
            <i class="fas fa-sign-out-alt"></i> Log out
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>