import { Person } from "./person";
import { BaseInterface, UserType } from "./baseinterface";

export interface User extends BaseInterface {
  person?: Person;
  username: string;
  company: any;
  password?: string;
  email: string;
  type: UserType;
  preferredBuilding?: string;
  marketAgreement?: boolean;
  termsAndConditions?: boolean;
}



export function getKeyByValue(object, value): string {
  return Object.keys(object).find(key => object[key] === value);
}