import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { SpinnerService } from 'app/services/spinner.service';
import { AppService } from 'app/services/app.service';
import { APIKeysService } from 'app/services/keys.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SweetalertService } from 'app/services/sweetalert.service';
import { APIKey } from 'app/interfaces/apikey';

@Component({
  selector: 'app-client-keys-edit',
  templateUrl: './client-keys-edit.component.html',
  styleUrls: ['./client-keys-edit.component.css']
})
export class ClientKeysEditComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private spinner: SpinnerService, private appService: AppService, private keysService: APIKeysService, private router: Router, private alert: SweetalertService) { }

  private id: string = "";

  public location_name: string = "";
  public location_lat: number = 51.5074086;
  public location_long: number = -0.128555;

  public original: APIKey;

  public form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required]),
    lat: new FormControl(null, [Validators.required]),
    long: new FormControl(null, [Validators.required]),
    location: new FormControl("", [Validators.required]),
    isOk: new FormControl(true, [Validators.requiredTrue]),
    note: new FormControl("")
  });

  ngOnInit() {
    this.spinner.activate();
    this.activatedRoute.params.subscribe(p => {
      this.id = p["id"];

      this.keysService.getOne(this.id).subscribe(res => {

        this.form.get("name").setValue(res.data.name);
        this.form.get("lat").setValue(res.data.lat);
        this.form.get("long").setValue(res.data.long);
        this.form.get("location").setValue(res.data.location);
        this.form.get("note").setValue(res.data.note);


        this.location_name = res.data.location;
        this.location_lat = res.data.lat;
        this.location_long = res.data.long;

        this.original = res.data;

        this.spinner.desactivate();
      })
    })
  }

  public lockLocation() {
    this.form.get("isOk").setValue(false);
  }

  public search() {
    if (this.form.get('location').value != "") {
      this.spinner.activate();
      this.appService.getGeolocation(this.form.get('location').value).subscribe(result => {
        this.spinner.desactivate();

        this.location_name = result.results[0].formatted_address;
        this.location_lat = result.results[0].geometry.location.lat;
        this.location_long = result.results[0].geometry.location.lng;

        this.form.get("lat").setValue(this.location_lat);
        this.form.get("long").setValue(this.location_long);

        this.form.get("isOk").setValue(true);
      });
    }
  }


  public doubleClick(event) {
    this.spinner.activate();
    this.appService.getGeolocation(event.coords.lat + " " + event.coords.lng).subscribe(result => {
      this.spinner.desactivate();

      this.location_name = result.results[0].formatted_address;
      this.location_lat = result.results[0].geometry.location.lat;
      this.location_long = result.results[0].geometry.location.lng;

      this.form.get("lat").setValue(this.location_lat);
      this.form.get("long").setValue(this.location_long);
      this.form.get('location').setValue(this.location_name);

      this.form.get("isOk").setValue(true);
    });
  }

  get location() {
    return this.form.get('location').value
  }

  public onSubmit() {
    let key: APIKey = this.form.getRawValue();

    this.spinner.activate();
    this.keysService.update(this.original, key).subscribe(x => {

      this.alert.success(
        "Success",
        "Changes to API Key have been saved. \nModules may take up to 24 hours to update."
      );

      this.spinner.desactivate();
      this.router.navigate(["weather", "keys"]);
    })
  }
}
