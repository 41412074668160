<app-fullscreen-spinner></app-fullscreen-spinner>
<div class="passwordBox animated fadeInDown">
  <div class="row">

    <div class="col-md-12">
      <div class="ibox-content recovery">

        <h2 class="font-bold">{{i18n.translate('recovery.title')}}</h2>

        <p>
          {{i18n.translate('recovery.instructions')}}
        </p>

        <div class="row">

          <div class="col-lg-12">
            <form *ngIf="typingUsername" class="m-t" role="form" (ngSubmit)="onSumitUsername()"
              [formGroup]="usernameForm">
              <div class="">
                <mat-form-field>
                  <input matInput type="text" class="form-control" formControlName="username"
                    placeholder="{{i18n.translate('recovery.username')}}" required="">
                </mat-form-field>
              </div>

              <button type="submit" [disabled]="usernameForm.invalid"
                class="btn btn-primary block full-width m-b">{{i18n.translate('general.labels.next')}}</button>

            </form>

            <form *ngIf="!typingUsername" class="m-t" role="form" (ngSubmit)="onSubmitPassword()"
              [formGroup]="verifyForm">
              <div class="">
                <mat-form-field>
                  <input matInput type="text" class="form-control" formControlName="code"
                    placeholder="{{i18n.translate('recovery.code')}}" required="">
                </mat-form-field>
              </div>
              <div class="input_w_tooltip">
                <mat-form-field>
                  <input matInput type="password" class="form-control" formControlName="newPassword"
                    placeholder="{{i18n.translate('recovery.newPassword')}}" required="">
                  <app-tooltip [message]="i18n.translate('tooltips.general.password')"></app-tooltip>
                </mat-form-field>
              </div>
              <div class="input_w_tooltip">
                <mat-form-field>
                  <input matInput type="password" class="form-control" formControlName="newPasswordConfirmation"
                    placeholder="{{i18n.translate('recovery.newPasswordConfirmation')}}" required="">
                  <app-tooltip [message]="i18n.translate('tooltips.general.confirmationPassword')"></app-tooltip>
                </mat-form-field>
              </div>

              <button type="submit" [disabled]="verifyForm.invalid"
                class="btn btn-primary block full-width m-b">{{i18n.translate('general.labels.next')}}</button>

            </form>

            <p class="text-muted text-center">
              <small>Already have an account?</small>
            </p>
            <a class="btn btn-sm btn-white btn-block" [routerLink]="['/auth/login']">Login</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-6">
      {{appService.productName}}
    </div>
    <div class="col-md-6 text-right">
      <small>© {{appService.releasedYear}}-{{appService.currentYear}}</small>
    </div>
  </div>
</div>
