<div class="row">
  <!-- <div class="col-md-1">

    <ng-container *ngIf="isValid">
      <i class="success far fa-check-circle"></i>
    </ng-container>
    <ng-container *ngIf="!isValid">
      <div matTooltipPosition="above" matTooltip="This Dark Sky key is not valid. Check if the key is correct.">
        <i class="error fas fa-exclamation-triangle"></i>
      </div>
    </ng-container>
  </div> -->
  <div class="col-md-11">
    <div class="row">
      <div class="col-md-12">
        <label>E-mail: &nbsp;</label> {{email}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>Dark Sky Key: &nbsp;</label> {{key}}
      </div>
    </div>
  </div>
</div>