import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { BsDropdownModule } from 'ngx-bootstrap';

import { BasicLayoutComponent } from './basicLayout.component';
import { BlankLayoutComponent } from './blankLayout.component';
import { TopNavigationLayoutComponent } from './topNavigationLayout.component';

import { NavigationComponent } from '../navigation/navigation.component';
import { FooterComponent } from '../footer/footer.component';
import { TopNavbarComponent } from '../topnavbar/topnavbar.component';
import { TopNavigationNavbarComponent } from '../topnavbar/topnavigationnavbar.component';
import { SpinnerModule } from 'app/components/common/spinner/spinner.module';
import { BreadcrumbsComponent } from 'app/components/common/layouts/breadCrumbs.component';
import { MatSelectModule } from '@angular/material/select';
import { FullscreenSpinnerComponent } from 'app/components/common/spinner/fullscreen-spinner.component';

@NgModule({
  declarations: [
    FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    TopNavigationLayoutComponent,
    TopNavbarComponent,
    TopNavigationNavbarComponent,
    BreadcrumbsComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    MatSelectModule,
    SpinnerModule
  ],
  exports: [
    FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    TopNavigationLayoutComponent,
    TopNavbarComponent,
    TopNavigationNavbarComponent,
    BreadcrumbsComponent,
  ],
})

export class LayoutsModule { }
