<div class="middle-box text-center animated fadeInDown">
  <h1>404</h1>
  <h3 class="font-bold">{{i18n.translate('page404.title')}}</h3>

  <div class="error-desc">
    {{i18n.translate('page404.body')}}
    <form class="form-inline m-t" role="form">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="{{i18n.translate('page404.searchForPage')}}">
      </div>
      <button type="submit" class="btn btn-primary">{{i18n.translate('general.buttons.search')}}</button>
    </form>
  </div>
</div>