import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { SpinnerService } from 'app/services/spinner.service';
import { AppI18nService } from 'app/services/app.i18n.service';
import { APIKeysService } from 'app/services/keys.service';
import { ClientKeysLocationComponent } from './client-keys-location/client-keys-location.component';
import { SweetalertService } from 'app/services/sweetalert.service';

@Component({
  selector: 'app-client-keys',
  templateUrl: './client-keys.component.html',
  styleUrls: ['./client-keys.component.css']
})
export class ClientKeysComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSource = new MatTableDataSource();

  public moduleLink: string = "";
  public displayedColumns = ["name", "location", "key", "hostid", "operations"];

  constructor(private spinner: SpinnerService, public i18n: AppI18nService, private keysService: APIKeysService, public dialog: MatDialog, private alert: SweetalertService) { }

  public available: number = 0;
  public maximum: number = 0;
  public keys: number = 0;

  private csvData = "";


  public downloadCSV() {
    var nameOfFileToDownload = "apiKeys.csv";
    var blob = new Blob([this.csvData], { type: 'text/csv' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, nameOfFileToDownload);
    } else {
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = nameOfFileToDownload;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  ngOnInit() {
    this.refresh();

    this.spinner.activate();
    this.keysService.getGrid().subscribe(x => {
      this.spinner.desactivate();

      this.csvData = x;
    })
  }

  private refresh() {
    this.spinner.activate();
    this.keysService.getAll().subscribe(x => {

      this.keys = x.MaxComponents - x.AvailableComponents;
      this.maximum = x.MaxComponents;
      this.available = x.AvailableComponents;

      this.dataSource.data = x.data;
      this.spinner.desactivate();
    })
  }

  public showMap(id) {
    const dialogRef = this.dialog.open(ClientKeysLocationComponent, {
      width: '600px',
      data: { entity: this.dataSource.data.find((x: any) => x._id == id) }
    });
  }

  public delete(id) {
    this.alert.custom({
      title: "Warning", text: "Do you want to delete this API Key? \nIf you delete this API Key then all associated modules will no longer receive weather information.", buttons: {
        yes: {
          text: "Yes",
          value: "yes"
        },
        no: {
          text: "No",
          value: "no"
        },
      },
      icon: "warning",

    }).then(x => {
      if (x == "cancel") {

      }
      else if (x == "yes") {
        this.spinner.activate();
        this.keysService.delete(id).subscribe(x => {
          this.spinner.desactivate();
          this.refresh();
        })
      }
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

}
