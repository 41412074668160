import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { LoginService } from "app/services/login.service";
import { UserType } from "app/interfaces/baseinterface";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private loginService: LoginService) {

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return Observable.create(observer => {

      this.loginService.isAuthenticated().subscribe(
        (res) => {

          // user is logged but in the unauth section
          if (state.url === "/auth/unauthorized") {
            observer.next(true);
          } else {
            this.checkUrlAndType(observer, state);
          }
        },
        (error) => {
          console.error(error.error);
          // user is not logged and in the auth section
          if (state.url.startsWith("/auth/")) {
            observer.next(true);
          } else {
            this.router.navigate(["auth/login"], { queryParams: { returnUrl: encodeURI(state.url) } });
            observer.next(false);
          }
        }
      );
    });
  }

  private checkUrlAndType(observer, state) {
    // the user is logged and we have the user info, so check the url

    if (state.url.startsWith("/admin/") && this.loginService.userInfo.type == UserType.admin) {
      observer.next(true);
    }
    else if (state.url.startsWith("/weather/") && this.loginService.userInfo.type == UserType.client) {
      observer.next(true);
    }
    else if (state.url == "auth/login") {
      if (this.loginService.userInfo.type == UserType.admin) {
        this.router.navigate(["/admin/"]);
      }
      else if (this.loginService.userInfo.type == UserType.client) {
        this.router.navigate(["/weather/"]);
      }
      observer.next(false);
    } else {
      this.router.navigate(["auth/unauthorized"]);
      observer.next(false);
    }
  }
}

