import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Error404Component } from "./error404/error404.component";
import { RootDashboardComponent } from "./root/root-dashboard/root-dashboard.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { SpinnerModule } from "app/components/common/spinner/spinner.module";
import { LoginComponent } from "app/views/auth/login/login.component";
import { RecoveryComponent } from "app/views/auth/recovery/recovery.component";
import { RootCompaniesComponent } from "./root/root-companies/root-companies.component";
import { MatTableModule } from "@angular/material/table";
import { IboxModule } from "app/components/common/ibox/ibox.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RootCreateCompanyComponent } from "./root/root-companies/root-create-company/root-create-company.component";
import { CompanyFormModule } from "app/components/common/company-form/company-form.module";
import { RootEditCompanyComponent } from "./root/root-companies/root-edit-company/root-edit-company.component";
import { RootUsersComponent } from "./root/root-users/root-users.component";
import { RootCreateUserComponent } from "./root/root-users/root-create-user/root-create-user.component";
import { RootEditUserComponent } from "./root/root-users/root-edit-user/root-edit-user.component";
import { UserFormModule } from "app/components/common/user-form/user-form.module";
import { BuildingFormModule } from "app/components/common/building-form/building-form.module";
import { BuildingCompleteFormModule } from "app/components/common/building-complete-form/building-complete-form.module";
import { MatSliderModule } from "@angular/material/slider";
import { CommonModule } from "@angular/common";
import { ProfileComponent } from "./profile/profile.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { RootBuildingsComponent } from "app/views/root/buildings/root-buildings.component";

import { VgCoreModule } from "videogular2/core";
import { VgControlsModule } from "videogular2/controls";
import { VgOverlayPlayModule } from "videogular2/overlay-play";
import { VgBufferingModule } from "videogular2/buffering";
import { AppHighstockModule } from "../components/common/highcharts/highstock/highstock.module";
import { TooltipModule } from "../components/common/tooltip/tooltip.module";
import { MyQRCodeModule } from "app/components/common/QRCode/qr-code.module";
import {
  MatAutocompleteModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatOptionModule,
  MatPaginatorModule,
  MatSelectModule,
  MatTabsModule,
  MatTooltipModule,
} from "@angular/material";
import { AdminBuildingsComponent } from './admin/admin-buildings/admin-buildings.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminCreateBuildingsComponent } from './admin/admin-buildings/admin-create-buildings/admin-create-buildings.component';
import { AdminEditBuildingsComponent } from './admin/admin-buildings/admin-edit-buildings/admin-edit-buildings.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AdminEditUserComponent } from './admin/admin-users/admin-edit-user/admin-edit-user.component';
import { AdminCreateUserComponent } from './admin/admin-users/admin-create-user/admin-create-user.component';
import { ClientDashboardComponent } from './client/client-dashboard/client-dashboard.component';
import { ClientKeysComponent } from './client/client-keys/client-keys.component';
import { ClientKeysCreateComponent } from './client/client-keys/client-keys-create/client-keys-create.component';
import { ClientKeysEditComponent } from './client/client-keys/client-keys-edit/client-keys-edit.component';
import { AgmCoreModule } from '@agm/core';
import { NewUserComponent } from './auth/new-user/new-user.component';
import { ClientKeysLocationComponent } from './client/client-keys/client-keys-location/client-keys-location.component';
import { ClientDarkskyKeysComponent } from './client/client-darksky-keys/client-darksky-keys.component';
import { ClientDarkskyKeysCreateComponent } from './client/client-darksky-keys/client-darksky-keys-create/client-darksky-keys-create.component';
import { ClientDarkskyKeysEditComponent } from './client/client-darksky-keys/client-darksky-keys-edit/client-darksky-keys-edit.component';
import { ClientDarkskyKeysInfoComponent } from './client/client-darksky-keys/client-darksky-keys-info/client-darksky-keys-info.component';
import { PolicyComponent } from './auth/policy/policy.component';
import { ClientHowToComponent } from './client/client-how-to/client-how-to.component';
import { ClientContactComponent } from './client/client-contact/client-contact.component';

@NgModule({
  declarations: [
    // Errors components
    Error404Component,
    UnauthorizedComponent,

    // Admin components
    AdminDashboardComponent,
    AdminUsersComponent,
    AdminCreateUserComponent,
    AdminEditUserComponent,
    AdminBuildingsComponent,
    AdminCreateBuildingsComponent,
    AdminEditBuildingsComponent,

    // Auth components
    LoginComponent,
    RecoveryComponent,

    // Root components
    RootBuildingsComponent,
    RootDashboardComponent,
    RootCompaniesComponent,
    RootCreateCompanyComponent,
    RootEditCompanyComponent,
    RootUsersComponent,
    RootCreateUserComponent,
    RootEditUserComponent,
    ProfileComponent,
    ContactsComponent,
    ChangePasswordComponent,
    ClientDashboardComponent,
    ClientKeysComponent,
    ClientKeysCreateComponent,
    ClientKeysEditComponent,

    NewUserComponent,

    ClientKeysLocationComponent,

    ClientDarkskyKeysComponent,

    ClientDarkskyKeysCreateComponent,

    ClientDarkskyKeysEditComponent,

    ClientDarkskyKeysInfoComponent,

    PolicyComponent,

    ClientHowToComponent,

    ClientContactComponent
  ],
  imports: [
    AgmCoreModule,
    MyQRCodeModule,
    TooltipModule,
    AppHighstockModule,
    MatTooltipModule,
    BrowserModule,
    RouterModule,
    SpinnerModule,
    IboxModule,
    MatTableModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    CompanyFormModule,
    UserFormModule,
    BuildingFormModule,
    MatTabsModule,
    BuildingCompleteFormModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatAutocompleteModule,
    CommonModule,
    FormsModule,

    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatDatepickerModule,
    MatListModule,

    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  exports: [],
  entryComponents: [ClientDarkskyKeysInfoComponent, ClientKeysLocationComponent, ClientDarkskyKeysCreateComponent, ClientDarkskyKeysEditComponent]
})

export class ViewsModule {
}
