import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { SpinnerService } from 'app/services/spinner.service';
import { DarkSkyService } from 'app/services/dark.sky.service';
import { DarkSkyKey } from 'app/interfaces/darksky';
import { SweetalertService } from 'app/services/sweetalert.service';

@Component({
  selector: 'app-client-darksky-keys-edit',
  templateUrl: './client-darksky-keys-edit.component.html',
  styleUrls: ['./client-darksky-keys-edit.component.css']
})
export class ClientDarkskyKeysEditComponent implements OnInit {

  constructor(private alert: SweetalertService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ClientDarkskyKeysEditComponent>, private spinner: SpinnerService, private darkSkyService: DarkSkyService) { }

  public form: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    apiKey: new FormControl("", [Validators.required])
  });

  private original: DarkSkyKey;

  ngOnInit() {
    this.spinner.activate();
    this.darkSkyService.getOne(this.data.id).subscribe(x => {
      this.spinner.desactivate();

      this.form.get('email').setValue(x.data.email);
      this.form.get('apiKey').setValue(x.data.apiKey);

      this.original = x.data;
    })
  }

  public onSubmit() {
    this.spinner.activate();
    this.darkSkyService.update(this.original, this.form.getRawValue()).subscribe(x => {
      this.alert.success(
        "Success",
        "Changes on the dark sky key have been saved."
      );

      this.spinner.desactivate();

      this.dialogRef.close(true);
    })
  }

}
