<app-fullscreen-spinner></app-fullscreen-spinner>
<!-- Wrapper-->
<div id="wrapper">

  <!-- Main page wrapper -->
  <div id="page-wrapper" class="gray-bg">

    <!-- Top navigation -->
    <app-topnavigationnavbar></app-topnavigationnavbar>

    <!-- Main view/routes wrapper-->
    <router-outlet></router-outlet>

    <!-- Footer -->
    <app-footer></app-footer>

  </div>
  <!-- End page wrapper-->

</div>
<!-- End wrapper-->
