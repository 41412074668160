<div class="wrapper wrapper-content">
  <div class="container">
    <app-ibox>
      <h2 title>NWeather How-To Guide</h2>

      <div class="howto-content" content>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h2>Description</h2>
          </div>
        </div>


        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <p>NWeather is a free Weather Module for Niagara N4. The weather information is brought into Niagara and
              made
              available via a standard component which can be linked to, in the normal way. It is easy to use and can be
              fully set up and configured via this website. All you need is five minutes or less. The weather data is
              supplied by Dark Sky and the module is supplied by Future Decisions Ltd.</p>
          </div>
        </div>


        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h3>SETUP INSTRUCTIONS (Total Time < 5 Minutes)</h3> </div> </div> <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <ol>
                    <li>
                      <strong>Step One –</strong> Get a Dark Sky Secret Key;
                    </li>
                    <li>
                      <strong>Step Two –</strong> Generate FD Weather Module API Keys;
                    </li>
                    <li>
                      <strong>Step Three –</strong> Download Nweather Module for N4;
                    </li>
                    <li>
                      <strong>Step Four –</strong> Set the FD API Key in the N4 Module for Autoconfiguration..
                    </li>
                  </ol>
                </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h2>Step One</h2>
            </div>
          </div>


          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h3>Get a Dark Sky Secret Key To Enable Weather Data.</h3>
            </div>
          </div>


          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ol>
                <li>
                  Go to <a href="https://darksky.net/dev/register" target="_blank">https://darksky.net/dev/register</a>
                  and register with an email address and a password of your
                  choice.
                </li>
              </ol>
              <img src="assets/images/howto/image1.png">
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ol start="2">
                <li>
                  You will then be asked to confirm your registration with a validation link sent via email.
                </li>
              </ol>
              <img src="assets/images/howto/image2.png">
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ol start="3">
                <li>
                  Once you have clicked the email link to validate your email you will be taken to the login page.
                </li>
              </ol>
              <img src="assets/images/howto/image3.png">
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ol start="4">
                <li>
                  Once you have logged in you will be shown your secret key (circled in red in the image below). You
                  will need to add this secret key under the Dark Sky Keys page in the NWeather Future Decisions
                  Website. The Weather module will then use this key to acquire the weather information.
                </li>
              </ol>
              <img src="assets/images/howto/image4.png">
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ol start="5">
                <li>
                  You can insert the email address and secret key into the NWeather - Dark Sky Keys as shown below. One
                  Dark Sky Key will support up to 40 Weather Modules within Niagara.
                </li>
              </ol>
              <img src="assets/images/howto/image6.png">
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h2>Step Two</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ol start="1">
                <li>
                  Get an FD API Key for your Weather Module.
                </li>
              </ol>
              <img src="assets/images/howto/image7.png">
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ol start="2">
                <li>
                  Setup a friendly name, location and some install notes for where you want the weather data


                </li>
              </ol>
              <img src="assets/images/howto/image8.png">
            </div>
          </div>


          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ol start="3">
                <li>
                  The key shown below with the red line under it is all you need to add the Niagara Weather Component.
                  All the other settings are automatically downloaded from this site.
                </li>
              </ol>
              <img src="assets/images/howto/image9.png">
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h2>Step Three</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ol start="1">
                <li>
                  Click The Download N4 Weather Module Link in the Main Menu. Once downloaded place the module in the
                  Niagara &lt;Version&gt; modules folder.
                </li>
              </ol>
              <img src="assets/images/howto/image10.png">
            </div>
          </div>


          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h2>Step Four</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ol start="1">
                <li>
                  When in your station open the Palette and just drag the weather component onto the wireframe.
                </li>
              </ol>
              <img src="assets/images/howto/image11.png">
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ol start="2">
                <li>
                  Go to the AX Property Sheet and Insert the FD API Key into the FD Key slot, click save then click
                  refresh. This is all you need to do as it will auto configure the component from the location settings
                  you have input on the nweather website.
                  <br />
                  There is a random delay for when the module will start its first update for which the time is
                  indicated on the slot labeled Next Update. Please note that the time is derived from the Niagara
                  system time but even if this is wrong or offset the weather data will always be current.
                </li>
              </ol>
              <img src="assets/images/howto/image13.png">
            </div>
          </div>

          <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ol start="3">
                  <li>
                    If you do not want to wait to check the connection please use the <strong>‘Test Connection’</strong> action as shown
                    below to immediately request the weather data.
                  </li>
                </ol>
                <img src="assets/images/howto/image12.png">
              </div>
            </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ol start="4">
                <li>
                  Please note that the folder 0 Hrs is the current weather conditions. The other folders +1Hrs to +48Hrs provide forecast weather data for the next 48 hours. The weather will<i> update every hour</i>.
                </li>
              </ol>
              <img src="assets/images/howto/image14.png">
            </div>
          </div>


          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              
              <p>We hope this helps with your Weather needs in Niagara!!</p>

              <p>Enjoy.</p>
            </div>
          </div>
          <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <h3>Notes</h3>
                  Selectable Units are SI Units or Imperial Units (US):
                  <ul>
                    <li>SI_Units (default)</li>
                    <li>Imperial_Units</li>
                  </ul>
                  <strong>SI units</strong> are as follows:
                  <ul>
                      <li>
                        summary: Any summaries containing temperature or snow accumulation units will have their values in degrees <strong>Celsius or in centimeters</strong> (respectively).
                      </li>
                      <li>
                      nearestStormDistance: <strong>Kilometers.</strong>
                    </li>
                    <li>
                      precipIntensity: <strong>Millimeters per hour.</strong>
                    </li>
                    <li>
                      precipIntensityMax: <strong>Millimeters per hour.</strong>
                    </li>
                    <li>
                      precipAccumulation: <strong>Centimeters.</strong>
                    </li>
                    <li>
                      temperature: <strong>Degrees Celsius.</strong>
                    </li>
                    <li>
                      temperatureMin: <strong>Degrees Celsius.</strong>
                    </li>
                    <li>
                      temperatureMax: <strong>Degrees Celsius.</strong>
                    </li>
                    <li>
                      apparentTemperature: <strong>Degrees Celsius.</strong>
                    </li>
                    <li>
                      dewPoint: <strong>Degrees Celsius.</strong>
                    </li>
                    <li>
                      windSpeed: <strong>Meters per second.</strong>
                    </li>
                    <li>
                      pressure: <strong>Hectopascals.</strong>
                    </li>
                    <li>
                      visibility: <strong>Kilometers. </strong>                     
                    </li>
                  </ul>
                  <strong> US Imperial units</strong> are as follows:
                  <ul>
                    <li>
                      summary: Any summaries containing temperature or snow accumulation units will have their values in degrees <strong>Fahrenheit or inches</strong> (respectively).
                    </li>
                    <li>
                      nearestStormDistance: <strong>Miles.</strong>
                    </li>
                    <li>
                      precipIntensity: <strong>Inches per hour.</strong>
                    </li>
                    <li>
                      precipIntensityMax: <strong>Inches per hour.</strong>
                    </li>
                    <li>
                      precipAccumulation: <strong>Inches.</strong>
                    </li>
                    <li>
                      temperature: <strong>Degrees Fahrenheit.</strong>
                    </li>
                    <li>
                      temperatureMin: <strong>Degrees Fahrenheit.</strong>
                    </li>
                    <li>
                      temperatureMax: <strong>Degrees Fahrenheit.</strong>
                    </li>
                    <li>
                      apparentTemperature: <strong>Degrees Fahrenheit.</strong>
                    </li>
                    <li>
                      dewPoint: <strong>Degrees Fahrenheit.</strong>
                    </li>
                    <li>
                      windSpeed: <strong>Miles per hour.</strong>
                    </li>
                    <li>
                      pressure: <strong>Pounds per Square Inch.</strong>
                    </li>
                    <li>
                      visibility: <strong>Miles.</strong>
                    </li>
                  </ul>
              </div>
            </div>
        </div>        
    </app-ibox>
  </div>
</div>