export interface BaseInterface {
  _id?: string;
  // isActive: boolean;
}


export enum UserType {
  root = 50,
  admin = 40,
  employee = 30,
  client = 0
}
