
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { Http, Response } from "@angular/http";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { environment } from "environments/environment";
import { UserType } from "app/interfaces/baseinterface";
import { AppI18nService } from "app/services/app.i18n.service";
import { LoginService } from "app/services/login.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { AppService } from "./app.service";
import { HttpClient } from '@angular/common/http';
import { deepCompareObj } from 'app/app.helpers';

@Injectable()
export class BaseService<T> {
  public rootUrl = "";

  private updateHeader() {
    return new Promise((resolve, reject) => {
      this.loginService.isAuthenticated().subscribe(
        () => {
          let session: any = JSON.parse(localStorage.getItem(this.appService.productKey + ".loggedUser"));
          resolve({ Authorization: session.token });
        },
        (error) => {
          resolve({ Authorization: "" });
        }
      );
    });
  };

  constructor(public http: HttpClient, public loginService: LoginService, public i18n: AppI18nService, public spinner: SpinnerService,
    public alert: SweetalertService, private appService: AppService) {

    const x: T = undefined;
    this.rootUrl = typeof (x);
  }

  public create(entity: T, isAuthenticated: boolean = true, restOfUrl?: string): Observable<any> {
    const body = {};
    body["entity"] = entity;

    return this.doPost(restOfUrl !== undefined ? restOfUrl : this.rootUrl, body, isAuthenticated).map(response => {
      return response;
    });
  }

  public delete(uid: string, isAuthenticated: boolean = true, restOfUrl?: string): Observable<any> {
    return this.doDelete(restOfUrl !== undefined ? this.rootUrl + restOfUrl : this.rootUrl, uid, isAuthenticated).map(response => response);
  }

  private returnDiffJson(originalJson, newJson) {
    // let diff = {};
    // for (const key of Object.keys(newJson)) {
    //   if (toString.call(newJson[key]) === '[object Object]' && Object.keys(newJson[key]).length > 0) {
    //     diff = Object.assign(diff, this.returnDiffJson(originalJson[key], newJson[key]));
    //   } else if (newJson[key] !== originalJson[key]) {
    //     diff[key] = newJson[key];
    //   }
    // }
    // return diff;

    let ret = {};
    for (let i in newJson) {
      if (!originalJson.hasOwnProperty(i) || newJson[i] !== originalJson[i]) {
        ret[i] = newJson[i];
      }
    }
    return ret;
  }

  public checkForCredentials(...userTypes: UserType[]) {
    if (this.loginService.userInfo === undefined || this.loginService.userInfo.loggedAt === undefined) {
      return observableThrowError({
        status: 401,
        errors: [{ message: this.i18n.translate("general.errors.notLogged") }]
      });
    }

    if (userTypes.indexOf(this.loginService.userInfo.type) === -1) {
      return observableThrowError({
        status: 403,
        errors: [{ message: this.i18n.translate("general.errors.noRights") }]
      });
    }

    return undefined;
  }

  public cleanArray(array: string[]) {
    return array.filter(x => x !== "");
  }

  public update(originalEntity: T, newEntity: T, isAuthenticated: boolean = true, restOfUrl?: string): Observable<any> {
    // const body = {};

    // body[ this.rootUrl ] = this.returnDiffJson( originalEntity, newEntity );

    const body = {};
    body["entity"] = deepCompareObj(originalEntity, newEntity);

    return this.doPatch((restOfUrl !== undefined ? this.rootUrl + restOfUrl : this.rootUrl) + "/" + originalEntity["_id"], body, isAuthenticated).
      map(response => response);
  }

  public getOne(uid: string, isAuthenticated: boolean = true, restOfUrl?: string): Observable<any> {
    return this.doGet(this.rootUrl, uid, restOfUrl, isAuthenticated).map(response => response as any);
  }

  public getAll(restofurl?: string, isAuthenticated: boolean = true, pagination?: IPaginationQuery, filter?: string,
    search?: ISearchParams[]): Observable<IGetResponse<T>> {

    let completeUrl = this.rootUrl;
    let stringParams: string[] = [];

    if (restofurl) {
      stringParams.push(restofurl);
    }

    if (filter) {
      stringParams.push("fullText=" + filter);
    }

    if (search) {
      for (let s of search) {
        stringParams.push(s.field + "=" + s.value);
      }
    }

    if (stringParams.length > 0) {
      completeUrl += "?" + stringParams.join("&");
    }

    return this.doGet(completeUrl, null, null, isAuthenticated).map(response => {
      return response as IGetResponse<T>
    });
  }

  public simpleErrorHandler = (error: any) => {
    return observableThrowError({ body: error });
  };

  public handleError = (errorResponse: Response | any) => {
    console.log("Error while requesting data from server:");
    console.log(errorResponse);

    try {
      if (errorResponse.status === 404) {
        this.alert.info(this.i18n.translate("general.errors.error"), this.i18n.translate("general.errors.notFound"));
      } else if (errorResponse.status == 401) {
        this.alert.info(this.i18n.translate("general.messages.securityWarning"),
          this.i18n.translate("general.messages.tokenExpired"));
        this.spinner.desactivate();
        this.loginService.logout(false, true, true);
      } else if (errorResponse.error.errors) {
        if (errorResponse.error.errors.message) {
          this.alert.info(this.i18n.translate("general.warnings.warning"), errorResponse.error.errors.message);
        }
        else {
          let errors = "";
          for (let err of errorResponse.error.errors) {
            errors += <string>err.message + ", ";
          }
          this.alert.info(this.i18n.translate("general.warnings.warning"), errors.substring(0, errors.length - 2));
        }
      }
    } catch {
      this.spinner.fullStop();
      this.alert.info(this.i18n.translate("general.warnings.warning"), this.i18n.translate("general.errors.callSupport"));
    }

    this.spinner.desactivate();
    return { status: errorResponse.status, errors: errorResponse.error.errors };
  };

  // Use this method to do something to ALL post request, such as passing tokens/timestamps/headers
  protected doPost(url: string, body: any, isAuthenticated: boolean = true): Observable<Response> {
    return Observable.create(observer => {
      if (isAuthenticated) {
        this.updateHeader().then(
          (header: any) => {
            this.http.post(environment.apiUrl + url, body, {
              headers: header
            }).subscribe(
              t => {
                observer.next(t);
                return;;
              },
              err => observer.error(this.handleError(err))
            );
          });
      }
      else {
        this.http.post(environment.apiUrl + url, body).subscribe(
          t => {
            observer.next(t);
            return;;
          },
          err => observer.error(this.handleError(err))
        );
      }
    });
  }

  // Use this method to do something to ALL patch request, such as passing tokens/timestamps/headers
  protected doPatch(url: string, body: any, isAuthenticated: boolean = true): Observable<Response> {
    return Observable.create(observer => {
      if (isAuthenticated) {
        this.updateHeader().then(
          (header: any) => {
            this.http.patch(environment.apiUrl + url, body, {
              headers: header
            }).subscribe(
              t => {
                observer.next(t);
                return;;
              },
              err => observer.error(this.handleError(err))
            );
          });
      }
      else {
        this.http.patch(environment.apiUrl + url, body).subscribe(
          t => {
            observer.next(t);
            return;;
          },
          err => observer.error(this.handleError(err))
        );
      }
    });
  }

  // Use this method to do something to ALL get request, such as passing tokens/timestamps/headers
  protected doGet(url: string, id?: string, restOfUlr?: string, isAuthenticated: boolean = true): Observable<any> {
    let completeUrl = "";

    if (id && restOfUlr) {
      completeUrl = environment.apiUrl + url + "/" + id + "?" + restOfUlr;
    } else if (id) {
      completeUrl = environment.apiUrl + url + "/" + id;
    } else {
      completeUrl = environment.apiUrl + url;
    }

    return new Observable<any>(observer => {
      if (isAuthenticated) {
        this.updateHeader().then(
          (header: any) => {
            this.http.get(completeUrl, {
              headers: header
            }).subscribe(
              t => {
                observer.next(t);
                return;;
              },
              err => observer.error(this.handleError(err))
            );
          });
      }
      else {
        this.http.get(completeUrl).subscribe(
          t => {
            observer.next(t);
            return;;
          },
          err => observer.error(this.handleError(err))
        );
      }
    });
  }

  // Use this method to do something to ALL delete request, such as passing tokens/timestamps/headers
  protected doDelete(url: string, id: string, isAuthenticated: boolean = true): Observable<any> {
    return new Observable<any>(observer => {
      if (isAuthenticated) {
        this.updateHeader().then(
          (header: any) => {
            this.http.delete(environment.apiUrl + url + "/" + id, {
              headers: header
            }).subscribe(
              t => {
                observer.next(t);
                return;;
              },
              err => observer.error(this.handleError(err))
            );
          });
      }
      else {
        this.http.delete(environment.apiUrl + url + "/" + id).subscribe(
          t => {
            observer.next(t);
            return;;
          },
          err => observer.error(this.handleError(err))
        );
      }
    });
  }

  public generateHtmlListOfErrors(returnedError: IReturnedError): string {
    let ret = "<ul style='text-align: left;'>";

    for (let error of returnedError.errors) {
      ret += "<li>" + error.message + "</li>";
    }

    ret += "</ul>";

    return ret;
  }
}

export interface IServiceError {
  field?: string;
  message: string;
}

export interface IReturnedError {
  status?: number;
  errors: IServiceError[];
}

export interface IPaginationQuery {
  take: number;
  skip: number;
  order?: Array<{
    field: string,
    op: DescAsc
  }>;
}

export enum DescAsc {
  asc = "ascending",
  desc = "descending"
}

export interface IGetResponse<T> {
  data: T[];

  [x: string]: any;

  page: number;
  pageSize: number;
  totalPages: number;
  totalRecordsFound: number;
}

export interface IPostResponse<T> {
  data: T;
}

export interface ISearchParams {
  field: string;
  value: string;
}
