<app-fullscreen-spinner></app-fullscreen-spinner>
<div *ngIf="needLogin" class="video-player">
  <vg-player>
    <video [vgMedia]="media" #media id="singleVideo" muted="true" preload="auto" autoplay crossorigin="true" loop>
      <source src="assets/25458998-preview.mp4" type="video/mp4">
    </video>
  </vg-player>
</div>
<div class="loginColumns animated fadeInDown">
  <div class="row row_bg">
    <div class="col-md-6">
      <img style="width: 360px;" src="assets/images/Blue_simple3x.png">
    </div>
    <div class="col-md-6">
      <div class="ibox-content">
        <div style="text-align: center">
          <h2 class="font-bold">NWeather Login</h2>
        </div>
        <form class="m-t" role="form" (ngSubmit)="onSubmit()" #loginForm="ngForm" action="index.html">
          <div class="form-group">
            <input type="email" class="form-control" name="email" ngModel
              placeholder="{{i18n.translate('login.labels.email')}}" required="">
          </div>
          <div class="form-group">
            <input type="password" class="form-control" name="password" ngModel
              placeholder="{{i18n.translate('login.labels.password')}}" required="" />
          </div>

          <div class="login_buttons">
            <button type="submit" class="btn btn-primary block full-width m-b" [disabled]="!canSubmit()">
              {{i18n.translate('general.buttons.login')}}
            </button>
          </div>

          <a style="font-size: 17px;" [routerLink]="['/auth/newUser']">
            <small>Create account</small>
          </a>
          <br />
          <a  style="font-size: 17px;" [routerLink]="['/auth/recovery']">
            <small>{{i18n.translate('login.buttons.forgot')}}</small>
          </a>
        </form>
        <p class="m-t" style="text-align: center">
          <small>{{appService.productName}} &copy; {{appService.currentYear}}</small>
        </p>
      </div>
    </div>
  </div>
  <!--<hr/>
  <div class="row">
    <div class="col-md-6">
      {{appService.productName}}
    </div>
    <div class="col-md-6 text-right">
      <small>© {{appService.releasedYear}} -{{currentYear()}}</small>
    </div>
  </div>-->
</div>

<div class="footer-login">
  <a href="http://futuredecisions.net/" target="_blank">
    <img src="assets/images/Future_Decisions.png">
  </a>
</div>