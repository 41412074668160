<app-fullscreen-spinner></app-fullscreen-spinner>
<div class="video-player">
  <vg-player>
    <video [vgMedia]="media" #media id="singleVideo" muted="true" preload="auto" autoplay crossorigin="true" loop>
      <source src="assets/25458998-preview.mp4" type="video/mp4">
    </video>
  </vg-player>
</div>

<div class="row">
  <div class="col-md-3"></div>
  <div class="col-md-6">
    <div class="loginColumns animated fadeInDown">
      <div class="ibox-content">
        <div style="text-align: center">
          <h2 class="font-bold">Create a new NWeather account</h2>
        </div>
        <form class="m-t" role="form" [formGroup]="userForm" (ngSubmit)="onSubmit()">

          <div class="row">
            <div class="form-group col-md-12">
              <input required class="form-control" name="name" formControlName="name"
                placeholder="{{i18n.translate('interfaces.user.name')}}">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <input required class="form-control" name="primaryEmail" formControlName="primaryEmail"
                placeholder="E-mail address">
            </div>
            <div class="form-group col-md-6">
              <input required class="form-control" name="company" formControlName="company"
                placeholder="{{i18n.translate('interfaces.user.company')}}">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <mat-select required name="country" class="form-control" formControlName="country"
                placeholder="{{i18n.translate('interfaces.user.country')}}">
                <mat-option *ngFor="let country of appService.countries" [value]="country.code">{{country.name}}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group col-md-6">
              <input class="form-control" required formControlName="city"
                placeholder="{{i18n.translate('interfaces.user.city')}}">
            </div>
          </div>
          <div class="row" formGroupName="telephone">
            <div class="form-group col-md-6">
              <input formControlName="number" class="form-control" pattern="[0-9]*" placeholder="Telephone (optional)">
            </div>
            <!-- <div class="form-group col-md-3">
              <input formControlName="internalExtension" class="form-control" pattern="[0-9]*"
                placeholder="{{i18n.translate('general.labels.internalExtension')}}">
            </div>
            <div class="form-group col-md-3">
              <mat-select (selectionChange)="changePhone($event)" name="type" class="form-control" formControlName="type"
                placeholder="Type">
                <mat-option *ngFor="let type of phoneTypes" [value]="type.type">{{type.name}}
                </mat-option>
              </mat-select>
            </div> -->
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12">
              <p>
                <mat-checkbox style="font-size:12px" formControlName="termsAndConditions"> I can confirm that I read and I agree to Future
                  Decisions
                </mat-checkbox>
                <a style="display: block;
                float: right;
                margin-right: 40px;
                margin-top: 5px;
                font-size: 11px;" [routerLink]="['/auth','policy']" target="_blank">Privacy & Terms and Conditions</a>
              </p>
              <p>
                <mat-checkbox style="font-size:12px"  formControlName="marketAgreement"> I would like to sign up to receive Future decisions
                  e-mail updates </mat-checkbox>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <div>
                <a [routerLink]="['/auth/']" routerLinkActive="router-link-active">Go back to
                  login</a>
                <button style="margin-left: 20px;" type="submit" [disabled]="!userForm.valid"
                  class="btn btn-w-m btn-primary">{{i18n.translate('general.buttons.save')}}</button>
              </div>
            </div>
          </div>

        </form>
        <p class="m-t" style="text-align: center; margin-top:60px;">
          <small>{{appService.productName}} &copy; {{appService.currentYear}}</small>
        </p>
      </div>
    </div>
  </div>
  <div class=" col-md-3">
  </div>
</div>


<!-- <div class="row form loginColumns">
    <div class="col-md-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>NWeather</h5>
        </div>
        <div class="ibox-content">
          <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
            <div class="form-container">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <h5>
                    Create a new NWeather account
                  </h5>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-5">
                  <mat-form-field>
                    <input required matInput name="primaryEmail" formControlName="primaryEmail"
                      placeholder="{{i18n.translate('interfaces.user.email')}}">
                  </mat-form-field>
                </div>
                <div class="col-lg-5">
                  <mat-form-field>
                    <input required matInput name="company" formControlName="company"
                      placeholder="{{i18n.translate('interfaces.user.company')}}">
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <mat-form-field>
                    <input matInput required name="name" formControlName="name"
                      placeholder="{{i18n.translate('interfaces.user.name')}}">
                  </mat-form-field>
                </div>

                <div class="col-lg-3">
                  <mat-form-field>
                    <mat-select required name="country" formControlName="country"
                      placeholder="{{i18n.translate('interfaces.user.country')}}">
                      <mat-option *ngFor="let country of appService.countries" [value]="country.code">{{country.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-3">
                  <mat-form-field>
                    <input matInput required formControlName="city"
                      placeholder="{{i18n.translate('interfaces.user.city')}}">
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <mat-form-field>
                    <span matPrefix>+&nbsp;</span>
                    <input matInput required name="mobilephone" pattern="[0-9]*" formControlName="mobilephone"
                      placeholder="{{i18n.translate('interfaces.user.mobilephone')}}">

                    <app-tooltip matSuffix [message]="i18n.translate('tooltips.general.phoneFormat')"></app-tooltip>
                  </mat-form-field>
                </div>
                <ng-container formGroupName="landline">
                  <div class="col-lg-4">
                    <mat-form-field>
                      <span matPrefix>+&nbsp;</span>
                      <input matInput required name="number" pattern="[0-9]*" formControlName="number"
                        placeholder="{{i18n.translate('interfaces.user.landline')}}">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-2">
                    <mat-form-field>
                      <input matInput name="internalExtension" formControlName="internalExtension"
                        placeholder="{{i18n.translate('interfaces.user.internalextension')}}">
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>

              <div class="row margin-b-22">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <small>{{i18n.translate('general.messages.required')}}</small>
                </div>
              </div>
              <div class="row">
                <div style="margin-top:22px;" class="col-lg-12">
                  <div>
                    <a [routerLink]="['/auth/']" routerLinkActive="router-link-active"><strong>Go back to
                        login</strong></a>
                    <button style="margin-left: 20px;" type="submit" [disabled]="!userForm.valid"
                      class="btn btn-w-m btn-primary">{{i18n.translate('general.buttons.save')}}</button>

                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="footer-login">
        <a href="http://futuredecisions.net/" target="_blank">
          <img src="assets/images/Future_Decisions.png">
        </a>
      </div>
    </div>
  </div> 
</div>-->

<div class="footer-login">
  <a href="http://futuredecisions.net/" target="_blank">
    <img src="assets/images/Future_Decisions.png">
  </a>
</div>