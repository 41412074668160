import { Injectable } from '@angular/core';
import { ElementRef } from '@angular/core/src/linker/element_ref';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpinnerService {

  public isLoading = new BehaviorSubject<boolean>(false);

  private _count: number = 0;
  get count(): number {
    return this._count;
  }

  get isBusy(): boolean {
    return this.count > 0;
  }

  public fullStop(){
    this._count = 0;
    this.isLoading.next(this.isBusy);
  }

  public activate() {
    this._count++;
    this.isLoading.next(this.isBusy);
  }

  public desactivate() {
    this._count--;
    if (this._count < 0) this._count = 0;
    this.isLoading.next(this.isBusy);

  }
}
