import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { CompanyService } from 'app/services/company.service';
import { Router } from '@angular/router';
import { AppI18nService } from 'app/services/app.i18n.service';
import { SpinnerService } from 'app/services/spinner.service';
import { LoginService } from 'app/services/login.service';
import { AppService } from 'app/services/app.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import { UserType } from 'app/interfaces/baseinterface';
import { PhoneType } from 'app/interfaces/contact';
import { UserService } from 'app/services/user.service';
import { User } from 'app/interfaces/user';
import { DISABLED } from '@angular/forms/src/model';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {

  public userForm: FormGroup = new FormGroup({
    type: new FormControl(UserType.client, Validators.required),
    company: new FormControl(""),
    country: new FormControl("", Validators.required),
    city: new FormControl("", Validators.required),
    name: new FormControl("", Validators.required),
    primaryEmail: new FormControl("", [Validators.required, Validators.email]),
    telephone:
      new FormGroup({
        number: new FormControl(""),
        internalExtension: new FormControl(""),
        type: new FormControl(PhoneType.Mobile)
      }),
    termsAndConditions: new FormControl(false, [Validators.requiredTrue]),
    marketAgreement: new FormControl(false)
  });

  public phoneTypes = [
    { type: PhoneType.Landline, name: "Landline" },
    { type: PhoneType.Mobile, name: "Mobile" }
  ]

  constructor(private userService: UserService, private router: Router,
    public i18n: AppI18nService, private spinnerService: SpinnerService,
    private alertService: SweetalertService, public appService: AppService, public loginService: LoginService) {
  }

  public changePhone(event) {
    if (event.value == "mobile") {
      this.userForm.get("telephone").get("internalExtension").disable();
    }
    else {
      this.userForm.get("telephone").get("internalExtension").enable();
    }
  }


  ngOnInit() {
  }

  get phone() {
    return (<FormArray>this.userForm.get("phones"))[0];
  }

  public onChangePhone(line, type) {
    if (type.value == PhoneType.Mobile) {
      (<FormGroup>(<FormArray>this.userForm.controls.telephones).controls[line]).controls["internalExtension"].disable();
    }
    else {
      (<FormGroup>(<FormArray>this.userForm.controls.telephones).controls[line]).controls["internalExtension"].enable();
    }
  }


  public onSubmit() {
    this.spinnerService.activate();

    let raw = this.userForm.getRawValue();

    let userData: User = {
      company: raw.company,
      email: raw.primaryEmail,
      type: UserType.client,
      username: raw.primaryEmail,
      person: {
        city: raw.city,
        contacts: { emails: [], telephones: [] },
        country: raw.country,
        landline: raw.telephone.type == PhoneType.Landline ?
          {
            internalExtension: raw.telephone.internalExtension,
            number: raw.telephone.number
          } : {
            internalExtension: "",
            number: ""
          }
        ,
        mobilePhone: raw.telephone.type == PhoneType.Mobile ? raw.telephone.number : "",
        name: raw.name,
        primaryEmail: raw.primaryEmail
      },
      marketAgreement: raw.marketAgreement,
      termsAndConditions: raw.termsAndConditions
    }

    console.log(userData);

    this.userService.create(userData).subscribe(x => {
      this.spinnerService.desactivate();

      this.router.navigate(["..", "auth", "login"]);
      this.alertService.success("User created", "Check your e-mail for more information.");
    });
  }


  public reset() {
    this.userForm.reset();
  }
}
