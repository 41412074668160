import { Injectable } from '@angular/core';
import swal from 'sweetalert';

@Injectable()
export class SweetalertService {

  public success(title: string, text: string, timer: number = 500000): Promise<any> {
    return swal({ title: title, text: text, icon: 'success', timer: timer });
  }

  public error(title: string, text: string, timer: number = 5000): Promise<any> {
    return swal({ title: title, text: text, icon: 'error', timer: timer });
  }

  public warning(title: string, text: string, timer: number = 5000): Promise<any> {
    return swal({ title: title, text: text, icon: 'warning', timer: timer });
  }

  public info(title: string, text: string, timer: number = 5000): Promise<any> {
    return swal({ title: title, text: text, icon: 'info', timer: timer });
  }

  public custom(options): Promise<any> {
    return swal(options);
  }
}
