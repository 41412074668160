
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService, IServiceError } from 'app/services/base.service';
import { Company } from 'app/interfaces/company';
import { UserType } from '../interfaces/baseinterface';

@Injectable()
export class CompanyService extends BaseService<Company> {

  rootUrl = 'companies';

  public checkForCredentials() {
    return super.checkForCredentials(UserType.root );
  }

  private checkCompany( company: Company ) {
    let errors: IServiceError[] = [];

    // company.contacts.emails = this.cleanArray(company.contacts.emails);
    // company.contacts.telephones = this.cleanArray(company.contacts.telephones);
    company.responsibles = this.cleanArray(company.responsibles);

    if ( company.name === '' ) {
      errors.push( { field: 'name', message: 'This company needs a name' } );
    }
    // if ( company.responsibles.length === 0 ) {
    //   errors.push( { field: 'responsiblesIds', message: 'This company needs to have at least one responsable' } );
    // }

    // if ( company.contacts.emails.length === 0 && company.contacts.telephones.length === 0 ) {
    //   errors.push( { field: 'contacts', message: 'This company needs to have at least one contact information' } );
    // }

    if ( errors.length > 0 ) {
      return observableThrowError( { status: 400, errors: errors } );
    }

    return undefined;
  }

  public create( company: Company ) {
    let err = this.checkForCredentials();
    if ( err ) {
      return err;
    } else {
      err = this.checkCompany( company );
    }

    return err ? err : super.create( company );
  }

  public update( oldCompany: Company, company: Company ) {
    let err = this.checkForCredentials();
    if ( err ) {
      return err;
    } else {
      err = this.checkCompany( company );
    }

    return err ? err : super.update( oldCompany, company );
  }

  public delete( uuid: string ) {
    const err = this.checkForCredentials();
    return err ? err : super.delete( uuid );
  }

  public getCompanyBuildingsTags(){
    const err = super.checkForCredentials( UserType.admin, UserType.employee );
    return err ? err : this.doGet( this.rootUrl + "/tags").
                                   map( response =>  response );
  }

  public manangeToken( uid: string, restOfUrl?: string ): Observable<any> {
    return this.doGet( this.rootUrl, uid + "/apiToken", restOfUrl ).map( response => response as any );
  }
}
