import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-client-darksky-keys-info',
  templateUrl: './client-darksky-keys-info.component.html',
  styleUrls: ['./client-darksky-keys-info.component.css']
})
export class ClientDarkskyKeysInfoComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ClientDarkskyKeysInfoComponent>) { }

  public key: string;
  public email: string;
  public isValid: boolean;

  ngOnInit() {
    this.key = this.data.key;
    this.email = this.data.email;
    this.isValid = this.data.isValid;
  }

}
