import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingFormComponent } from './building-form.component';
import { MatSelectModule, MatOptionModule, MatFormFieldModule, MatInputModule, MatDatepickerModule } from '@angular/material';
import { IboxModule } from 'app/components/common/ibox/ibox.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    IboxModule,
    FormsModule,
    ReactiveFormsModule, 
    MatDatepickerModule,
    RouterModule
  ],
  declarations: [BuildingFormComponent],
  exports: [BuildingFormComponent]
})
export class BuildingFormModule { }
