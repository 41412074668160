<div class="ibox ">
    <div class="ibox-title">
        <ng-content select="[title]"></ng-content>
        <ng-content select="[header]"></ng-content>
        
        <div class="ibox-tools">
            <ng-content select="tools"></ng-content>
        </div>
    </div>
    <div class="ibox-content"> <!--  [ngClass]="{'sk-loading': spinnerService.isBusy}" -->
        <!-- <app-spinner [type]="spinner"></app-spinner> -->
        <ng-content select="[content]"></ng-content>
    </div>
</div>