<div class="wrapper wrapper-content  animated fadeInRight">
  <app-ibox>
    <h5 title>{{i18n.translate('interfaces.user.user')}}</h5>
    <div content>
      <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <div class="form-container">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h5>
                {{i18n.translate('general.labels.user')}}
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <mat-form-field>
                <input matInput required name="name" formControlName="name"
                  placeholder="{{i18n.translate('interfaces.user.name')}}">
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field>
                <input required matInput name="primaryEmail" formControlName="primaryEmail"
                  placeholder="{{i18n.translate('interfaces.user.email')}}">
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field>
                <input required matInput name="company" formControlName="company" placeholder="Company">
              </mat-form-field>
            </div>
            <!-- <div class="col-lg-3" *ngIf="userType == adminType">
              <mat-form-field>
                <mat-select required formControlName="type" placeholder="{{i18n.translate('interfaces.user.type')}}">
                  <mat-option *ngFor="let type of userTypes" [value]="type.key">{{type.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3" *ngIf="userType == rootType">
              <mat-form-field>
                <mat-select required formControlName="type" placeholder="{{i18n.translate('interfaces.user.type')}}">
                  <mat-option *ngFor="let type of rootTypes" [value]="type.key">{{type.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3" *ngIf="userType == rootType && userForm.value.type == adminType">
              <mat-form-field>
                <mat-select required formControlName="company" placeholder="{{i18n.translate('interfaces.user.company')}}">
                  <mat-option></mat-option>
                  <mat-option *ngFor="let company of companyList" [value]="company._id">{{company.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
          </div>

          <div class="row">
            <div class="col-lg-3">
              <mat-form-field>
                <mat-select required name="country" formControlName="country"
                  placeholder="{{i18n.translate('interfaces.user.country')}}">
                  <mat-option *ngFor="let country of appService.countries" [value]="country.code">{{country.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field>
                <input matInput required formControlName="city"
                  placeholder="{{i18n.translate('interfaces.user.city')}}">
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field>
                <input matInput required formControlName="mobilephone" pattern="[0-9]*"
                  placeholder="{{i18n.translate('general.labels.telephone')}}">
              </mat-form-field>
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-lg-6">
              <mat-form-field>
                <span matPrefix>+&nbsp;</span>
                <input matInput required name="mobilephone" pattern="[0-9]*" formControlName="mobilephone"
                       placeholder="{{i18n.translate('interfaces.user.mobilephone')}}">

                <app-tooltip matSuffix [message]="i18n.translate('tooltips.general.phoneFormat')"></app-tooltip>
              </mat-form-field>
            </div>
            <ng-container formGroupName="landline">
              <div class="col-lg-4">
                <mat-form-field>
                  <span matPrefix>+&nbsp;</span>
                  <input matInput required name="number" pattern="[0-9]*" formControlName="number"
                         placeholder="{{i18n.translate('interfaces.user.landline')}}">
                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <mat-form-field>
                  <input matInput name="internalExtension" formControlName="internalExtension"
                         placeholder="{{i18n.translate('interfaces.user.internalextension')}}">
                </mat-form-field>
              </div>
            </ng-container>
          </div> -->

          <div class="row margin-b-22">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <small>{{i18n.translate('general.messages.required')}}</small>
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h5>
                {{i18n.translate('general.labels.otherContacts')}}
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6" formArrayName="telephones">
              <div class="row"
                   *ngFor="let telephone of userForm['controls'].telephones['controls']; let i = index; let first = first; let last = last"
                   [formGroupName]="i">
                <div class="col-lg-6">
                  <mat-form-field>
                    <input formControlName="number" pattern="^[+]*[0-9]*" matInput placeholder="{{i18n.translate('general.labels.telephone')}}">
                  </mat-form-field>
                </div>

                <div class="col-lg-3">
                  <mat-form-field>
                    <input formControlName="internalExtension" pattern="^[+]*[0-9]*" matInput
                           placeholder="{{i18n.translate('general.labels.internalExtension')}}">
                  </mat-form-field>
                </div>
                <div class="col-lg-2">
                  <mat-form-field>
                    <mat-select formControlName="type" (selectionChange)="onChangePhone(i, $event)"
                                placeholder="{{i18n.translate('general.labels.phoneType')}}">
                      <mat-option *ngFor="let t of types" [value]="t.value">{{t.key}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>


                <div class="col-lg-1" style="margin-top:10px">
                  <a (click)="removePhone(i)">
                    <i class="fas fa-times-circle fa-2x"></i>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <mat-hint align="start">
                    <strong>
                      <a (click)="addPhone()">
                        {{i18n.translate('general.buttons.addTelephone')}}
                      </a>
                    </strong>
                  </mat-hint>
                </div>
              </div>
            </div>
            <div class="col-lg-6" formArrayName="emails">
              <div class="row" *ngFor="let email of userForm['controls'].emails['controls']; let i = index; let first = first; let last = last"
                   [formGroupName]="i">
                <div class="col-lg-11">
                  <mat-form-field>
                    <input type="email" [formControl]="userForm.controls.emails.controls[i]" matInput
                           placeholder="{{i18n.translate('general.labels.email')}}">
                  </mat-form-field>
                </div>

                <div class="col-lg-1" style="margin-top:15px">
                  <a (click)="removeEmail(i)">
                    <i class="far fa-times-circle fa-2x"></i>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <mat-hint align="start">
                    <strong>
                      <a (click)="addEmail()">
                        {{i18n.translate('general.buttons.addEmail')}}
                      </a>
                    </strong>
                  </mat-hint>
                </div>
              </div>

            </div>

          </div> -->
          <div class="row">
            <div class="col-lg-12 margin-t-22">
              <div>
                <button type="submit" [disabled]="!userForm.valid"
                  class="btn btn-w-m btn-primary">{{i18n.translate('general.buttons.save')}}</button>
                <button type="button" (click)="goBack()"
                  class="btn btn-w-m btn-white">{{i18n.translate('general.buttons.cancel')}}</button>
                <button type="button" (click)="reset()"
                  class="btn btn-w-m btn-success">{{i18n.translate('general.buttons.reset')}}</button>
                <!-- <button *ngIf="!!this.user" type="button" (click)="delete()" class="btn btn-w-m btn-danger">
                  {{i18n.translate('general.buttons.delete')}}
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </app-ibox>

  <app-ibox *ngIf="isOwner">
    <!-- <app-ibox *ngIf="false"> -->
    <h5 title>{{i18n.translate('general.labels.passwordReset')}}</h5>
    <div content>
      <form [formGroup]="passwordForm" (ngSubmit)="changePassword()">
        <div class="form-container">
          <div class="row">
            <div class="col-lg-6">
              <mat-form-field>
                <input formControlName="oldPassword" type="password" matInput
                  placeholder="{{i18n.translate('general.labels.oldPassword')}}">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <mat-form-field>
                <input formControlName="newPassword" type="password" matInput
                  placeholder="{{i18n.translate('general.labels.newPassword')}}">
                <app-tooltip matSuffix [message]="i18n.translate('tooltips.general.password')"></app-tooltip>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field>
                <input formControlName="newPasswordConfirmation" type="password" matInput
                  placeholder="{{i18n.translate('general.labels.newPasswordConfirmation')}}">
                <app-tooltip matSuffix [message]="i18n.translate('tooltips.general.confirmationPassword')">
                </app-tooltip>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 margin-t-22">
              <div>
                <button type="submit" [disabled]="(!validateNewEmail())"
                  class="btn btn-w-m btn-primary">{{i18n.translate('general.buttons.save')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>
  </app-ibox>
</div>