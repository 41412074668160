import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from 'app/services/spinner.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import { DarkSkyService } from 'app/services/dark.sky.service';

@Component({
  selector: 'app-client-darksky-keys-create',
  templateUrl: './client-darksky-keys-create.component.html',
  styleUrls: ['./client-darksky-keys-create.component.css']
})
export class ClientDarkskyKeysCreateComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ClientDarkskyKeysCreateComponent>, private spinner: SpinnerService, private alert: SweetalertService, private darkSkyService: DarkSkyService) { }

  public form: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    apiKey: new FormControl("", [Validators.required])
  });

  ngOnInit() {
  }

  public onSubmit() {
    this.spinner.activate();
    this.darkSkyService.create(this.form.getRawValue()).subscribe(x => {
      this.spinner.desactivate();
      this.alert.success(
        "Success",
        "New Dark Sky Key has been saved."
      );

      this.dialogRef.close(true);
    })
    
  }
}
