import { Component, OnInit, ViewChild } from "@angular/core";
import { AppService } from "app/services/app.service";
import { AppI18nService } from "app/services/app.i18n.service";
import { NgForm } from "@angular/forms";
import { LoginService } from "app/services/login.service";
import { SpinnerService } from "app/services/spinner.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SweetalertService } from "../../../services/sweetalert.service";
import { UserService } from "../../../services/user.service";
import { UserType } from "../../../interfaces/baseinterface";

@Component({
  selector: "app-login",
  templateUrl: "login.template.html",
  styleUrls: ["./login.component.css"]
})

export class LoginComponent implements OnInit {

  @ViewChild("loginForm") form: NgForm;
  public needLogin = false;

  constructor(private route: ActivatedRoute, private userService: UserService,
    private alert: SweetalertService, public appService: AppService,
    public i18n: AppI18nService, private loginService: LoginService, public spinner: SpinnerService, private router: Router) {

    this.spinner.activate();
    this.loginService.isAuthenticated().subscribe(
      (res) => {
        this.route.queryParams.subscribe(query => {
          if (query["returnUrl"]) {
            this.router.navigate([query["returnUrl"]]);
          } else {
            if (this.loginService.userInfo.type == UserType.admin) {
              this.router.navigate(["/admin/"]);
            } else if (this.loginService.userInfo.type == UserType.client) {
              this.router.navigate(["/weather/"]);
            }
          }
          this.spinner.desactivate();
        },
          (err) => {
            console.log(err);
            this.spinner.desactivate();
          });
      },
      (error) => {
        console.log(error);
        this.needLogin = true;
        this.spinner.desactivate();
      }
    );
  }

  ngOnInit() {
  }

  public currentYear() {
    return new Date().getFullYear();
  }

  public canSubmit() {
    return this.form.valid;
  }

  public onSubmit() {
    if (this.canSubmit) {
      this.spinner.activate();
      this.loginService.authenticate(this.form.value.email, this.form.value.password).subscribe(
        (result: any) => {
          if (result.forcePasswordChange) {
            localStorage.setItem(this.appService.productKey + ".loggedUser", JSON.stringify(result));
            this.loginService.params = { username: this.form.value.email, password: this.form.value.password, loginInfo: result };
            this.alert.info(this.i18n.translate("general.messages.securityWarning"),
              this.i18n.translate("general.messages.updatePassword"));
            this.spinner.desactivate();
            this.router.navigate(["/auth/changePassword"], { relativeTo: this.route });
          } else {
            localStorage.setItem(this.appService.productKey + ".loggedUser", JSON.stringify(result));
            let userInfo = this.loginService.setUserInfo(result);
            let type = userInfo.type;
            console.log(userInfo);
            this.route.queryParams.subscribe(query => {
              if (query["returnUrl"]) {
                this.router.navigate([query["returnUrl"]]);
              } else {
                if (this.loginService.userInfo.type == UserType.admin) {
                  this.router.navigate(["/admin/"]);
                } else if (this.loginService.userInfo.type == UserType.client) {
                  this.router.navigate(["/weather/"]);
                }
              }

              this.spinner.desactivate();
            },
              (err) => {
                console.log(err);
                this.spinner.desactivate();
              });
          }
        },
        error => {
          console.log(error);
          this.spinner.desactivate();
          if (error.message === "User is not confirmed.") {
            this.loginService.params = { username: this.form.value.email, password: this.form.value.password };
            this.alert.info(this.i18n.translate("general.messages.securityWarning"),
              this.i18n.translate("general.messages.updatePassword"));
            this.router.navigate(["/auth/verify", this.form.value.email]);
          } else if (error.code === "UserNotFoundException") {
            this.alert.info(this.i18n.translate("login.messages.error.title"),
              this.i18n.translate("general.errors.invalidUsernamePassword"));
          } else {
            this.alert.info(this.i18n.translate("login.messages.error.title"),
              this.i18n.translate("general.errors.invalidUsernamePassword"));
          }
        }
      );
    }
  }
}
