import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-client-keys-location',
  templateUrl: './client-keys-location.component.html',
  styleUrls: ['./client-keys-location.component.css']
})
export class ClientKeysLocationComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ClientKeysLocationComponent>) { }

  public location_lat: number = 0;
  public location_long: number = 0;
  public location_name: string = "";

  ngOnInit() {
    this.location_lat = this.data.entity.lat;
    this.location_long = this.data.entity.long;
    this.location_name = this.data.entity.name;
  }

}
