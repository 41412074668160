import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-how-to',
  templateUrl: './client-how-to.component.html',
  styleUrls: ['./client-how-to.component.css']
})
export class ClientHowToComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
