<div class="wrapper wrapper-content">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-ibox>
          <h2 title>Contact us</h2>
          <div content>
            <div class="row">

              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <h3>Send us a message</h3>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <mat-form-field>
                        <input required matInput placeholder="Subject" formControlName="subject">
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <mat-form-field>
                        <textarea style="height: 130px" matInput placeholder="Message" required
                          formControlName="message"></textarea>
                      </mat-form-field>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <button type="submit" [disabled]="!form.valid"
                        class="pull-right btn btn-w-m btn-primary">Send</button>
                    </div>
                  </div>

                </form>
              </div>
              <div style="padding-left: 60px;" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3>Feel free to visit our main website for more products and services.</h3>
                  </div>
                </div>


                <div class="row margin-t-22">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <a href="https://futuredecisions.net/" target="_blank">
                      <img style="width: 165px" src="assets/images/Future_Decisions.png">
                    </a>
                  </div>
                </div>

                <!-- <div class="row margin-t-22">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    12 Warwick Road<br /> Reading<br /> RG2 7AX
                  </div>
                </div>

                <div class="row row margin-t-22">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <a href="tel:02081444917"><i class="fas fa-phone"></i>&nbsp; 02081444917</a>
                  </div>
                </div> -->

                <div class="row margin-t-22">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <a href="mailto:info@futuredecisions.net"><i class="far fa-envelope"></i> &nbsp;
                      info@futuredecisions.net</a>
                  </div>
                </div>



              </div>
            </div>
          </div>
        </app-ibox>
      </div>
    </div>
  </div>
</div>