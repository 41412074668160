import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { AppI18nService } from "app/services/app.i18n.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { Router } from "@angular/router";
import { SpinnerService } from "app/services/spinner.service";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { User, getKeyByValue } from "app/interfaces/user";
import { Company } from "app/interfaces/company";
import { CompanyService } from "app/services/company.service";
import { AppService } from "app/services/app.service";
import { LoginService } from "app/services/login.service";
import { UserType } from "app/interfaces/baseinterface";
import { PhoneType } from "app/interfaces/contact";

@Component({
  selector: "app-user-form",
  templateUrl: "./user-form.component.html",
  styleUrls: ["./user-form.component.css"]
})
export class UserFormComponent implements OnInit, OnChanges {
  @Output() onsubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Input() user: User;
  @Input() disabledFields: string[] = [];

  public isOwner: boolean = false;
  public companyList: Company[];
  public types: { key: string, value: string }[] = [];

  public passwordForm: FormGroup = new FormGroup({});

  public userForm: FormGroup = new FormGroup({});

  constructor(private companyService: CompanyService, private router: Router,
    public i18n: AppI18nService, private _location: Location, private spinnerService: SpinnerService,
    private alertService: SweetalertService, public appService: AppService, public loginService: LoginService) {
  }

  public userType: number;
  public rootType = UserType.root;
  public adminType = UserType.admin;
  public rootTypes = [
    {
      key: UserType.root,
      name: "Root"
    },
    {
      key: UserType.admin,
      name: "Admin"
    }
  ];

  public userTypes = [
    {
      key: UserType.admin,
      name: "Administrator"
    },
    {
      key: UserType.employee,
      name: "Employee"
    }
  ];

  ngOnInit() {

    for (let value in PhoneType) {
      this.types.push({ key: value, value: PhoneType[value] });
    }

    this.userType = this.loginService.userInfo.type;

    this.init();
    // if (this.loginService.userInfo.type == UserType.root) {
    //   this.spinnerService.activate();
    //   this.companyService.getAll().subscribe(results => {
    //     this.companyList = results.data;
    //     this.spinnerService.desactivate();
    //   });
    // }
  }

  public init() {
    this.userForm = new FormGroup({
      type: new FormControl(UserType.employee, Validators.required),
      company: new FormControl(""),
      country: new FormControl("", Validators.required),
      city: new FormControl("", Validators.required),
      name: new FormControl("", Validators.required),
      mobilephone: new FormControl("", Validators.required),
      landline: new FormGroup({
        number: new FormControl("", [Validators.required]),
        internalExtension: new FormControl("")
      }),
      primaryEmail: new FormControl("", [Validators.required, Validators.email]),
      emails: new FormArray([]),
      telephones: new FormArray([])
    });
  }

  public onChangePhone(line, type) {
    if (type.value == PhoneType.Mobile) {
      (<FormGroup>(<FormArray>this.userForm.controls.telephones).controls[line]).controls["internalExtension"].disable();
    }
    else {
      (<FormGroup>(<FormArray>this.userForm.controls.telephones).controls[line]).controls["internalExtension"].enable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (this.user) {
        this.isOwner = this.loginService.userInfo.primaryEmail == this.user.email;

        if (this.isOwner) {
          this.passwordForm = new FormGroup({
            oldPassword: new FormControl("", Validators.required),
            newPasswordConfirmation: new FormControl("", [Validators.required, this.validatePassword]),
            newPassword: new FormControl("", [Validators.required])
          }
          );
          this.passwordForm.controls["newPassword"].valueChanges.subscribe!({
            next: (value) => {
              this.passwordForm.controls["newPasswordConfirmation"].updateValueAndValidity();
            }
          });
        }

        let canEdit = (this.loginService.userInfo.type == UserType.root ||
          (this.user.company == this.loginService.userInfo.company && this.loginService.userInfo.type == UserType.admin)) ||
          this.isOwner;

        if (this.loginService.userInfo.type == UserType.root) {
          this.userForm = new FormGroup({
            type: new FormControl({ value: this.user.type, disabled: !canEdit }, Validators.required),
            company: new FormControl({ value: this.user.company, disabled: false }, Validators.required),
            country: new FormControl({ value: this.user.person.country, disabled: !canEdit }, Validators.required),
            city: new FormControl({ value: this.user.person.city, disabled: !canEdit }, Validators.required),
            name: new FormControl({ value: this.user.person.name, disabled: !canEdit }, Validators.required),
            mobilephone: new FormControl(
              { value: this.user.person.mobilePhone ? this.user.person.mobilePhone.replace("+", "") : "", disabled: !canEdit },
              Validators.required),
            primaryEmail: new FormControl({ value: this.user.email, disabled: true }, [Validators.required, Validators.email]),
          });
        } else {
          this.userForm = new FormGroup({
            type: new FormControl({ value: this.user.type, disabled: !canEdit }, Validators.required),
            company: new FormControl({ value: this.user.company, disabled: false },  [Validators.required]),
            country: new FormControl({ value: this.user.person.country, disabled: !canEdit }, Validators.required),
            city: new FormControl({ value: this.user.person.city, disabled: !canEdit }, Validators.required),
            name: new FormControl({ value: this.user.person.name, disabled: !canEdit }, Validators.required),
            mobilephone: new FormControl(
              { value: this.user.person.mobilePhone ? this.user.person.mobilePhone.replace("+", "") : "", disabled: !canEdit },
              Validators.required),
            primaryEmail: new FormControl({ value: this.user.email, disabled: true }, [Validators.required, Validators.email])
          });
        }

      }
    } catch (err) {
      console.error(err);
      this.alertService.error(this.i18n.translate("general.errors.error"), this.i18n.translate("general.errors.unknown"));
      this.spinnerService.desactivate();
    }
  }

  public changePassword() {
    if (this.passwordForm.valid) {
      this.spinnerService.activate();
      let registrationUser = {
        existingPassword: this.passwordForm.value.oldPassword,
        password: this.passwordForm.value.newPassword,
        username: ""
      };
      this.loginService.changePassword(registrationUser).subscribe(
        result => {
          this.spinnerService.desactivate();
          this.alertService.success(this.i18n.translate("general.messages.success"),
            this.i18n.translate("general.messages.passwordReseted"));
          this.passwordForm = new FormGroup({
            oldPassword: new FormControl("", Validators.required),
            newPasswordConfirmation: new FormControl("", [Validators.required, this.validatePassword]),
            newPassword: new FormControl("", [Validators.required])
          });
          this.passwordForm.controls["newPassword"].valueChanges.subscribe!({
            next: (value) => {
              this.passwordForm.controls["newPasswordConfirmation"].updateValueAndValidity();
            }
          });
        },
        err => {
          console.error(err);
          this.spinnerService.desactivate();

          if (err.code == "InvalidParameterException") {
            this.alertService.error(this.i18n.translate("general.messages.error"), this.i18n.translate("tooltips.general.password"),
              0);
          } else if (err.code == "NotAuthorizedException") {
            this.alertService.error(this.i18n.translate("general.messages.error"),
              this.i18n.translate("general.errors.invalidPassword"), 0);
          }
        }
      );
    }
  }

  public goBack() {
    this._location.back();
  }

  public onSubmit() {

    if (this.userForm.valid &&
      (this.userForm.value.type === this.rootType || this.userType === this.adminType || this.userForm.value.company !== "")) {
      this.userForm.get("primaryEmail").setValue(
        (this.userForm.get("primaryEmail").value).toLowerCase()
      );
      this.onsubmit.emit(this.userForm);
    }
    else {
      console.error(this.userForm);
    }
  }

  public delete() {
    this.onDelete.emit(this.user);
  }

  public reset() {
    this.userForm.reset();
  }

  public addEmail() {
    // if (!(<FormArray>(<FormGroup>this.userForm.controls.responsibles).controls.emails))
    //   (<FormGroup>this.userForm.controls.responsibles).addControl("emails",new FormArray([new FormControl('', Validators.email)]))
    // else {
    (<FormArray>this.userForm.controls.emails).push(
      new FormControl("", [Validators.required, Validators.email])
    );
    // }
  }

  public removeEmail(pos) {
    (<FormArray>this.userForm.controls.emails).removeAt(pos);
  }

  public addPhone() {
    (<FormArray>this.userForm.controls.telephones).push(
      new FormGroup({
        number: new FormControl("", [Validators.required]),
        internalExtension: new FormControl({ value: "", disabled: true }),
        type: new FormControl("", Validators.required)
      })
    );
  }

  public removePhone(pos) {
    (<FormArray>this.userForm.controls.telephones).removeAt(pos);
  }

  public validateNewEmail() {
    let formStatus = this.passwordForm.valid;

    if (this.passwordForm.value.newPassword == this.passwordForm.value.newPasswordConfirmation && this.passwordForm.value.newPassword !=
      "") {

      // this.passwordForm.get("newPassword").setErrors(null);
      // this.passwordForm.get("newPasswordConfirmation").setErrors(null);

      return formStatus;
    }

    // this.passwordForm.get("newPassword").setErrors({ "invalid": true });
    // this.passwordForm.get("newPasswordConfirmation").setErrors({ "invalid": true });

    return false;
  }

  private validatePassword(f: FormControl) {
    if (f.parent) {
      return f.parent.controls["newPassword"].value == f.parent.controls["newPasswordConfirmation"].value ? null :
        { validatePassword: { valid: false } };
    }
    return null;
  }
}
