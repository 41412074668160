<app-fullscreen-spinner></app-fullscreen-spinner>

<div class="middle-box text-center loginscreen   animated fadeInDown">
  <div>
    <div>

      <h1 class="logo-name">IN+</h1>

    </div>
    <h3>{{i18n.translate('newPassword.labels.welcome')}}</h3>
    <p>{{i18n.translate('newPassword.labels.info')}}</p>
    <form class="m-t" role="form" (ngSubmit)="onSubmit()" [formGroup]="form">
      <ng-container *ngIf="!loginService.params">
        <div class="">
          <mat-form-field>
            <input matInput type="text" class="form-control" formControlName="username" placeholder="{{i18n.translate('newPassword.labels.username')}}"
                   required="">
          </mat-form-field>
        </div>
        <div class="">
          <mat-form-field>
            <input matInput type="password" class="form-control" formControlName="oldPassword" placeholder="{{i18n.translate('newPassword.labels.oldPassword')}}"
                   required="">
          </mat-form-field>
        </div>
      </ng-container>
      <div class="">
        <mat-form-field>
          <input matInput type="password" class="form-control" formControlName="newPassword" placeholder="{{i18n.translate('newPassword.labels.newPassword')}}"
                 required="">
          <app-tooltip matSuffix [message]="i18n.translate('tooltips.general.password')"></app-tooltip>
        </mat-form-field>
      </div>
      <div class="">
        <mat-form-field>
          <input matInput type="password" class="form-control" formControlName="newPasswordConfirmation" placeholder="{{i18n.translate('newPassword.labels.newPasswordConfirmation')}}"
                 required="">
          <app-tooltip matSuffix [message]="i18n.translate('tooltips.general.confirmationPassword')"></app-tooltip>
        </mat-form-field>
      </div>
      <button [disabled]="!form.valid" type="submit" class="btn btn-primary block full-width m-b">{{i18n.translate('newPassword.buttons.changePassword')}}</button>
    </form>
    <p class="m-t">
      <small>{{appService.productName}} &copy; {{appService.releasedYear}} - {{appService.currentYear}}</small>
    </p>
  </div>
</div>
