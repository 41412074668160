
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService, IPaginationQuery } from 'app/services/base.service';
import { User } from 'app/interfaces/user';
import { UserType } from 'app/interfaces/baseinterface';
import { environment } from 'environments/environment';

@Injectable()
export class UserService extends BaseService<User> {

  rootUrl = 'users';

  public create(user: User) {
    let userHasPermission = true;
    // if (this.loginService.userInfo.type ===UserType.root
    //   && (user.type ===UserType.root || user.type === UserType.admin)) {
    //   userHasPermission = true;
    // } else if (this.loginService.userInfo.type === UserType.admin
    //   && user.type !==UserType.root
    //   && user.company === this.loginService.userInfo.company) {
    //   userHasPermission = true;
    // }

    if (userHasPermission) {
      let errors = [];
      if (user.person.primaryEmail === '') {
        errors.push(this.i18n.translate('general.errors.invalidValue', 'email'));
      }

      if (user.person.name === '') {
        errors.push(this.i18n.translate('general.errors.invalidValue', 'name'));
      }

      // if (user.contacts.emails.length === 0 && user.contacts.telephones.length === 0)
      //   errors.push(this.i18n.translate("general.errors.invalidValue", "contacts"));

      if (user.username === '') {
        errors.push(this.i18n.translate('general.errors.invalidValue', 'username'));
      }

      // if (user.type === UserType.Visitor && user.validUntil === undefined) {
      //   errors.push(this.i18n.translate('general.errors.invalidValue', 'valid date'));
      // }

      if (user.password === '') {
        errors.push(this.i18n.translate('general.errors.invalidValue', 'password'));
      }

      if (errors.length > 0) {
        return observableThrowError({ errors: errors });
      }

      return super.create(user, false);
    } else {
      // console.log("aa");
      throw observableThrowError({ errors: [this.i18n.translate('general.errors.noRights')] });
    }
  }

  public update(oldUser: User, user: User) {
    let errors = [];
    if (oldUser._id !== user._id) {
      return observableThrowError({ errors: [this.i18n.translate('general.errors.noRights', 'user ' + user._id)] });
    }

    // if (user.login !== this.loginService.userInfo.login)
    //   errors.push( this.i18n.translate("general.errors.notChangeable", "login") );

    /*if (user.type !== this.loginService.userInfo.type) {
      errors.push(this.i18n.translate('general.errors.notChangeable', 'type'));
    }*/

    if (user.person.name === '') {
      errors.push(this.i18n.translate('general.errors.invalidValue', 'name'));
    }

    if (errors.length > 0) {
      return observableThrowError({ errors: errors });
    }

    return super.update(oldUser, user);
  }

  public changeToClient(id: string) {
    return this.doPost(environment.apiUrl + 'user/changeToClient', { id: id })
      .map(response => response)
  }
}
