<div class="middle-box text-center animated fadeInDown">
  <h1>{{i18n.translate('unauthorized.code')}}</h1>
  <h3 class="font-bold">{{i18n.translate('unauthorized.title')}}</h3>

  <div class="error-desc">
    {{i18n.translate('unauthorized.body')}}
    <br/> {{i18n.translate('unauthorized.action')}}
    <br/>
    <a [routerLink]="[generateLink()]" class="btn btn-primary m-t">{{i18n.translate('unauthorized.buttons.goTo')}}</a>
  </div>
</div>