<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-12">
      <h5>New Dark Sky key</h5>
    </div>
  </div>
  <div class="row margin-t-22">
    <div class="col-md-4">
      <mat-form-field>
        <input matInput required name="email" formControlName="email" placeholder="E-mail">
      </mat-form-field>
    </div>
    <div class="col-md-8">
      <mat-form-field>
        <input matInput required name="apiKey" formControlName="apiKey" placeholder="Dark Sky Secret Key">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <button type="submit" [disabled]="form.invalid" style="margin-right: 20px;" class=" pull-right btn btn-w-m btn-primary">
      <i class="fas fa-save"></i> Save
    </button>
  </div>
</form>