import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { AppHighstockComponent } from "./highstock.component";

@NgModule( {
  imports: [
    BrowserModule
  ],
  declarations: [ AppHighstockComponent ],
  exports: [ AppHighstockComponent ]
} )
export class AppHighstockModule {
}
