import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from "@angular/common";

import { ROUTES } from "./app.routes";
import { AppComponent } from "./app.component";
// App views
import { ViewsModule } from "./views/views.module";
// App modules/components
import { LayoutsModule } from "./components/common/layouts/layouts.module";
// App guards
import { AuthGuard } from "app/guards/auth.guard";
// App services
import { UserService } from "./services/user.service";
import { CompanyService } from "./services/company.service";
import { BuildingService } from "./services/building.service";
import { AppI18nService } from "app/services/app.i18n.service";
import { LoginService } from "app/services/login.service";
import { AppService } from "./services/app.service";
import { BaseRequestOptions } from "@angular/http";
import { SpinnerService } from "./services/spinner.service";
import { SweetalertService } from "./services/sweetalert.service";
import { MockBackend } from "@angular/http/testing";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule } from "@angular/common/http";
import { QRCodeModule } from "angular2-qrcode";

import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { ValidatorsService } from "./services/validators.service";
import { APIKeysService } from './services/keys.service';
import { DarkSkyService } from './services/dark.sky.service';
import { ContactService } from './services/contact.service';

@NgModule( {
  declarations: [
    AppComponent,
  ],
  imports: [
    QRCodeModule,
    BrowserModule,
    FormsModule,
    LayoutsModule,
    ViewsModule,
    RouterModule.forRoot( ROUTES ),
    AgmCoreModule.forRoot( {
      apiKey: "AIzaSyBaPzUSovkTa1USfJuop9yU6BzG-NfueNo"
    } ),
    HttpClientModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    // fakeBackendProvider,
    MockBackend,
    BaseRequestOptions,
    AuthGuard,
    UserService,
    CompanyService,
    BuildingService,
    LoginService,
    AppI18nService,
    AppService,
    APIKeysService,
    SpinnerService,
    SweetalertService,
    ValidatorsService,
    MockBackend,
    BaseRequestOptions,
    DarkSkyService,
    ContactService
  ],
  bootstrap: [ AppComponent ]
} )

export class AppModule {
}

