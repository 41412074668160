<div class="container">
  <div class="row">
    <div class="col-md-12">
      <p><img src="assets/images/Future_Decisions_2.png"></p>
      <h1>Privacy & Terms and Conditions</h1>
      <h2>Please read this privacy policy & Terms and Conditions carefully.</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <p>
        This privacy policy applies between you, the User of this Website and Future Decisions Ltd, the owner and
        provider of this Website. Future Decisions Ltd takes the privacy of your information very seriously. This
        privacy policy applies to our use of any and all Data collected by us or provided by you in relation to your use
        of the Website.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h3>Please read this privacy policy carefully.</h3>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Definitions and interpretation</h2>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>
          In this privacy policy, the following definitions are used
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <strong>Data</strong>
    </div>

    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
      collectively all information that you submit to Future Decisions Ltd via the Website. This definition
      incorporates, where applicable, the definitions provided in the Data Protection Laws;
    </div>

  </div>

  <div class="row">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <strong>Cookies</strong>
    </div>
    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
      a small text file placed on your computer by this Website when you visit certain parts of the Website and/or when
      you use certain features of the Website. Details of the cookies used by this Website are set out in the clause
      below (<strong>Cookies</strong>);
    </div>

  </div>

  <div class="row">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <strong>Data Protection Laws</strong>
    </div>
    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
      any applicable law relating to the processing of personal Data, including but not limited to the Directive
      96/46/EC (Data Protection Directive) or the GDPR, and any national implementing laws, regulations and secondary
      legislation, for as long as the GDPR is effective in the UK;
    </div>

  </div>

  <div class="row">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <strong>GDPR</strong>
    </div>
    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
      the General Data Protection Regulation (EU) 2016/679;
    </div>

  </div>

  <div class="row">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <strong>Future Decisions Ltd,
        we</strong> or <strong>us</strong>
    </div>
    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
      Future Decisions Ltd, a company incorporated in England and Wales with registered number 09292608 whose registered
      office is at 12 Warwick Road, Berkshire, RG27AX;
    </div>

  </div>

  <div class="row">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <strong>UK and EU Cookie Law</strong>
    </div>
    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
      the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended by the Privacy and Electronic
      Communications (EC Directive) (Amendment) Regulations 2011;
    </div>

  </div>

  <div class="row">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <strong>User</strong> or <strong>you</strong>
    </div>
    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
      any third party that accesses the Website and is not either (i) employed by Future Decisions Ltd and acting in the
      course of their employment or (ii) engaged as a consultant or otherwise providing services to Future Decisions Ltd
      and accessing the Website in connection with the provision of such services; and
    </div>

  </div>

  <div class="row">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <strong>Website</strong>
    </div>
    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
      the website that you are currently using, futuredecisions.net, and any sub-domains of this site unless expressly
      excluded by their own terms and conditions.
    </div>


  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol start="2">
        <li>
          In this privacy policy, unless the context requires a different interpretation:
        </li>
      </ol>
      <ol class="sub-list">
        <li>the singular includes the plural and vice versa;</li>
        <li>references to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses, schedules or
          appendices of this privacy policy;</li>
        <li>a reference to a person includes firms, companies, government entities, trusts and partnerships;</li>
        <li>"including" is understood to mean "including without limitation";</li>
        <li>reference to any statutory provision includes any modification or amendment of it;</li>
        <li>the headings and sub-headings do not form part of this privacy policy.</li>
      </ol>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Scope of this privacy policy</h2>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>
          This privacy policy applies only to the actions of Future Decisions Ltd and Users with respect to this
          Website. It
          does not extend to any websites that can be accessed from this Website including, but not limited to, any
          links we
          may provide to social media websites.
        </li>
        <li>
          For purposes of the applicable Data Protection Laws, Future Decisions Ltd is the "data controller". This means
          that Future Decisions Ltd determines the purposes for which, and the manner in which, your Data is processed.
        </li>
      </ol>
    </div>
  </div>



  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Data collected</h2>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>
          We may collect the following Data, which includes personal Data, from you:
        </li>
      </ol>

      <ol class="sub-list">
        <li>
          name;
        </li>
        <li>
          IP address (automatically collected);
        </li>
        <li>
          operating system (automatically collected);
        </li>
        <li>
          email;
        </li>
        <li>
          API Keys;
        </li>
        <li>
          host ID;
        </li>
        <li>
          passwords (encrypted); in each case, in accordance with this privacy policy.
        </li>
      </ol>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>How we collect Data</li>
      </ol>
      <ol class="sub-list">
        <li>
          when you contact us through the Website, by telephone, post, e-mail or through any other means;
        </li>
        <li>
          when you register with us and set up an account to receive our products/services;
        </li>
        <li>
          when you use our services;
        </li>
      </ol>
      <p>in each case, in accordance with this privacy policy.</p>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Data that is collected automatically</h2>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>
          To the extent that you access the Website, we will collect your Data automatically, for example:
        </li>
      </ol>

      <ol class="sub-list">
        <li>
          we automatically collect some information about your visit to the Website. This information helps us to make
          improvements to Website content and navigation, and includes your IP address, the date, times and frequency
          with
          which you access the Website and the way you use and interact with its content.</li>
        <li>
          we will collect your Data automatically via cookies, in line with the cookie settings on your browser. For
          more
          information about cookies, and how we use them on the Website, see the section below, headed "Cookies".</li>
      </ol>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Our use of Data</h2>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>Any or all of the above Data may be required by us from time to time in order to provide you with the best
          possible service and experience when using our Website. Specifically, Data may be used by us for the following
          reasons:</li>
      </ol>
      <ol class="sub-list">
        <li>
          internal record keeping;</li>
        <li>
          improvement of our products / services;</li>
        <li>
          transmission by email of marketing materials that may be of interest to you;</li>
        <li>
          contact for market research purposes which may be done using email, telephone, fax or mail. Such information
          may
          be used to customise or update the Website;</li>
      </ol>

      <p>in each case, in accordance with this privacy policy.</p>

      <ol start="2">
        <li>We may use your Data for the above purposes if we deem it necessary to do so for our legitimate interests.
          If you are not satisfied with this, you have the right to object in certain circumstances (see the section
          headed "Your rights" below).</li>
        <li>For the delivery of direct marketing to you via e-mail, we'll need your consent, whether via an opt-in or
          soft-opt-in:</li>
      </ol>

      <ol class="sub-list">
        <li>soft opt-in consent is a specific type of consent which applies when you have previously engaged with us
          (for example, you contact us to ask us for more details about a particular product/service, and we are
          marketing similar products/services). Under "soft opt-in" consent, we will take your consent as given unless
          you opt-out.
        </li>
        <li>for other types of e-marketing, we are required to obtain your explicit consent; that is, you need to take
          positive and affirmative action when consenting by, for example, checking a tick box that we'll provide.</li>
        <li> if you are not satisfied about our approach to marketing, you have the right to withdraw consent at any
          time.
          To find out how to withdraw your consent, see the section headed "Your rights" below.</li>
      </ol>

      <ol start="4">
        <li>When you register with us and set up an account to receive our services, the legal basis for this processing
          is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a
          contract.</li>
      </ol>

    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Who we share Data with</h2>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>We may share your Data with the following groups of people for the following reasons:</li>
      </ol>

      <ol class="sub-list">
        <li>our employees, agents and/or professional advisors - The information obtained is used for marketing and
          system management purposes.;
        </li>
      </ol>
      <p>
        in each case, in accordance with this privacy policy.
      </p>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Keeping Data secure</h2>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>We will use technical and organisational measures to safeguard your Data, for example:</li>
      </ol>

      <ol class="sub-list">
        <li>access to your account is controlled by a password and a user name that is unique to you.
        </li>
        <li>
          we store your Data on secure servers.
        </li>
      </ol>

      <ol start="2">
        <li>
          Technical and organisational measures include measures to deal with any suspected data breach. If you suspect
          any misuse or loss or unauthorised access to your Data, please let us know immediately by contacting us via
          this e-mail address: info@futuredecisions.net.
        </li>
        <li>
          If you want detailed information from Get Safe Online on how to protect your information and your computers
          and devices against fraud, identity theft, viruses and many other online problems, please visit
          www.getsafeonline.org. Get Safe Online is supported by HM Government and leading businesses.
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Data retention</h2>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>Unless a longer retention period is required or permitted by law, we will only hold your Data on our systems
          for the period necessary to fulfil the purposes outlined in this privacy policy or until you request that the
          Data be deleted.</li>
        <li>
          Even if we delete your Data, it may persist on backup or archival media for legal, tax or regulatory purposes.
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Your rights</h2>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>You have the following rights in relation to your Data:</li>
      </ol>

      <ol class="sub-list">
        <li>
          <strong>Right to access</strong> - the right to request (i) copies of the information we hold about you at any
          time, or (ii)
          that we modify, update or delete such information. If we provide you with access to the information we hold
          about you, we will not charge you for this, unless your request is "manifestly unfounded or excessive." Where
          we are legally permitted to do so, we may refuse your request. If we refuse your request, we will tell you the
          reasons why.
        </li>
        <li>
          <strong>Right to correct</strong> - the right to have your Data rectified if it is inaccurate or incomplete.
        </li>
        <li>
          <strong>Right to erase</strong> - the right to request that we delete or remove your Data from our systems.
        </li>
        <li>
          <strong>Right to restrict our use of your Data</strong> - the right to "block" us from using your Data or
          limit the way in which we can use it.
        </li>
        <li>
          <strong>Right to data portability</strong> - the right to request that we move, copy or transfer your Data.
        </li>
        <li>
          <strong>Right to object</strong> - the right to object to our use of your Data including where we use it for
          our legitimate interests.
        </li>
      </ol>

      <ol start="2">
        <li>To make enquiries, exercise any of your rights set out above, or withdraw your consent to the processing of
          your Data (where consent is our legal basis for processing your Data), please contact us via this e-mail
          address: info@futuredecisions.net.</li>
        <li>If you are not satisfied with the way a complaint you make in relation to your Data is handled by us, you
          may be able to refer your complaint to the relevant data protection authority. For the UK, this is the
          Information Commissioner's Office (ICO). The ICO's contact details can be found on their website at
          https://ico.org.uk/.</li>
        <li>It is important that the Data we hold about you is accurate and current. Please keep us informed if your
          Data changes during the period for which we hold it.</li>
      </ol>

    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Links to other websites</h2>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>
          This Website may, from time to time, provide links to other websites. We have no control over such websites
          and are not responsible for the content of these websites. This privacy policy does not extend to your use of
          such websites. You are advised to read the privacy policy or statement of other websites prior to using them.
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Changes of business ownership and control</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>
          Future Decisions Ltd may, from time to time, expand or reduce our business and this may involve the sale
          and/or the transfer of control of all or part of Future Decisions Ltd. Data provided by Users will, where it
          is relevant to any part of our business so transferred, be transferred along with that part and the new owner
          or newly controlling party will, under the terms of this privacy policy, be permitted to use the Data for the
          purposes for which it was originally supplied to us.
        </li>
        <li>
          We may also disclose Data to a prospective purchaser of our business or any part of it.
        </li>
        <li>

          In the above instances, we will take steps with the aim of ensuring your privacy is protected.
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Cookies</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>
          This Website may place and access certain Cookies on your computer. Future Decisions Ltd uses Cookies to
          improve your experience of using the Website and to improve our range of services. Future Decisions Ltd has
          carefully chosen these Cookies and has taken steps to ensure that your privacy is protected and respected at
          all times.</li>
        <li>All Cookies used by this Website are used in accordance with current UK and EU Cookie Law.</li>
        <li>Before the Website places Cookies on your computer, you will be presented with a message bar requesting your
          consent to set those Cookies. By giving your consent to the placing of Cookies, you are enabling Future
          Decisions Ltd to provide a better experience and service to you. You may, if you wish, deny consent to the
          placing of Cookies; however certain features of the Website may not function fully or as intended.</li>
        <li>
          This Website may place the following Cookies:
        </li>
      </ol>
    </div>
  </div>

  <div class="row align-left">
    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
      <strong>Type of Cookie</strong>
    </div>
    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
      <strong>Purpose</strong>
    </div>
  </div>

  <div class="row align-left">
    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
      Strictly necessary cookies
    </div>
    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
      These are cookies that are required for the operation of our website. They include, for example, cookies
      that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing
      services.
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol start="5">
        <li>
          You can find a list of Cookies that we use in the Cookies Schedule.
        </li>
        <li>
          You can choose to enable or disable Cookies in your internet browser. By default, most internet browsers
          accept Cookies but this can be changed. For further details, please consult the help menu in your internet
          browser.
        </li>
        <li>
          You can choose to delete Cookies at any time; however you may lose any information that enables you to access
          the Website more quickly and efficiently including, but not limited to, personalisation settings.
        </li>
        <li>
          It is recommended that you ensure that your internet browser is up-to-date and that you consult the help and
          guidance provided by the developer of your internet browser if you are unsure about adjusting your privacy
          settings.
        </li>
        <li>
          For more information generally on cookies, including how to disable them, please refer to aboutcookies.org.
          You will also find details on how to delete cookies from your computer.
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>General</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>
          You may not transfer any of your rights under this privacy policy to any other person. We may transfer our
          rights under this privacy policy where we reasonably believe your rights will not be affected.
        </li>
        <li>
          If any court or competent authority finds that any provision of this privacy policy (or part of any provision)
          is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed
          to be deleted, and the validity and enforceability of the other provisions of this privacy policy will not be
          affected.
        </li>
        <li>
          Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed
          a waiver of that, or any other, right or remedy.
        </li>
        <li>
          This Agreement will be governed by and interpreted according to the law of England and Wales. All disputes
          arising under the Agreement will be subject to the exclusive jurisdiction of the English and Welsh courts.
        </li>
      </ol>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Changes to this privacy policy</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <ol>
        <li>
          Future Decisions Ltd reserves the right to change this privacy policy as we may deem necessary from time to
          time or as may be required by law. Any changes will be immediately posted on the Website and you are deemed to
          have accepted the terms of the privacy policy on your first use of the Website following the alterations.
        </li>
      </ol>
      <p>
        You may contact Future Decisions Ltd by email at info@futuredecisions.net.
      </p>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>Cookies</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <p>Below is a list of the cookies that we use. We have tried to ensure this is complete and up to date, but if you
        think that we have missed a cookie or there is any discrepancy, please let us know.</p>
      <p>Strictly necessary</p>
      <p>We use the following strictly necessary cookies:</p>
    </div>
  </div>


  <div class="row align-left">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <strong>Description of Cookie</strong>
    </div>

    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
      <strong>Purpose</strong>
    </div>

  </div>
  <div class="row align-left">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      Security
    </div>

    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
      For website security
    </div>

  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2>No Reverse Engineering</h2>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      In order to protect the trade secrets and other proprietary information of Future Decisions, users (Licensee)
      warrant that, except as expressly permitted under applicable law, it will not reverse engineer, disassemble, or
      otherwise attempt to derive the source code form of the Licensed Materials.
    </div>
  </div>



  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
      THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
      AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF
      CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS
      IN THE SOFTWARE.
    </div>
  </div>



  <div class="row mini-logo">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <img src="assets/images/Future_Decisions.min.png">
    </div>
  </div>

</div>