import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from 'app/services/spinner.service';
import { AppService } from 'app/services/app.service';
import { APIKey } from 'app/interfaces/apikey';
import { APIKeysService } from 'app/services/keys.service';
import { Router } from '@angular/router';
import { SweetalertService } from 'app/services/sweetalert.service';

@Component({
  selector: 'app-client-keys-create',
  templateUrl: './client-keys-create.component.html',
  styleUrls: ['./client-keys-create.component.css']
})
export class ClientKeysCreateComponent implements OnInit {

  constructor(private spinner: SpinnerService, private appService: AppService, private keysService: APIKeysService, private router: Router, private alert: SweetalertService) { }


  public location_name: string = "";
  public location_lat: number = 51.5074086;
  public location_long: number = -0.128555;

  public form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required]),
    lat: new FormControl(null, [Validators.required]),
    long: new FormControl(null, [Validators.required]),
    location: new FormControl("", [Validators.required]),
    isOk: new FormControl(false, [Validators.requiredTrue]),
    note: new FormControl("")
  });

  public count = 0;

  ngOnInit() {
  }

  public search() {
    if (this.form.get('location').value != "") {
      this.spinner.activate();
      this.appService.getGeolocation(this.form.get('location').value).subscribe(result => {
        this.spinner.desactivate();

        this.location_name = result.results[0].formatted_address;
        this.location_lat = result.results[0].geometry.location.lat;
        this.location_long = result.results[0].geometry.location.lng;

        this.form.get("lat").setValue(this.location_lat);
        this.form.get("long").setValue(this.location_long);

        this.form.get("isOk").setValue(true);
      });
    }
  }

  get location() {
    return this.form.get('location').value
  }

  public lockLocation() {
    this.form.get("isOk").setValue(false);
  }

  public doubleClick(event) {
    this.spinner.activate();
    this.appService.getGeolocation(event.coords.lat + " " + event.coords.lng).subscribe(result => {
      this.spinner.desactivate();

      this.location_name = result.results[0].formatted_address;
      this.location_lat = result.results[0].geometry.location.lat;
      this.location_long = result.results[0].geometry.location.lng;

      this.form.get("lat").setValue(this.location_lat);
      this.form.get("long").setValue(this.location_long);
      this.form.get('location').setValue(this.location_name);

      this.form.get("isOk").setValue(true);
    });
  }

  public onSubmit() {
    let key: APIKey = this.form.getRawValue();

    this.spinner.activate();
    this.keysService.create(key).subscribe(x => {


      this.alert.success(
        "Success",
        "New API key has been created."
      );

      this.spinner.desactivate();


      // if(this.count < 40){
      //   this.onSubmit(); 
      // }

      this.router.navigate(["weather", "keys"]);
    })
  }
}
