<div class="wrapper wrapper-content">
  <div class="container">
    <div class="row">
      <form class="col-md-12" [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-ibox>
          <h5 title>Edit API Key</h5>
          <div content>
            <div class="row">
              <div class="col-md-12">
                <p>Please, inform the new key name and the location on the map below.</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <mat-form-field>
                  <input required matInput name="name" formControlName="name" placeholder="Friendly name">
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <input matInput name="note" formControlName="note" placeholder="Notes">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>To select a location, you search for the address in the field below or double click in the desired
                  position on the map.</p>
              </div>
            </div>
            <div class="row">

              <div class="col-md-4">
                <mat-form-field>
                  <input matInput required (input)="lockLocation()" (keydown.enter)="$event.preventDefault()"
                    (keyup.enter)="search()" name="location" formControlName="location" placeholder="Weather Location">
                  <button type="button" matSuffix mat-button [disabled]="location == ''" (click)="search()"
                    style="margin: 0; background: none; border: none;">
                    <i class="fas fa-search"></i>
                  </button>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <button type="submit" style="margin-top: 17px;" [disabled]="form.invalid"
                  class="pull-right btn-xs btn btn-w-m  btn-primary">
                  <i class="fas fa-save"></i> Update
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                
                  <small>Fields marked with * are required.</small>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <agm-map (mapDblClick)="doubleClick($event)" [disableDoubleClickZoom]="true" [clickableIcons]="false"
                  [streetViewControl]="false" [zoom]="16" [latitude]="location_lat" [longitude]="location_long">
                  <agm-marker *ngIf="location_name != ''" [latitude]="location_lat" [longitude]="location_long">
                    <agm-info-window [isOpen]="true">{{location_name}} <br /> Latitude: {{ location_lat }} - Longitude:
                      {{location_long}} </agm-info-window>
                  </agm-marker>
                </agm-map>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12"></div>
            </div>
          </div>
        </app-ibox>
      </form>
    </div>
  </div>
</div>