import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserFormComponent } from './user-form.component';
import { MatFormFieldModule, MatOptionModule, MatSelectModule, MatInputModule, MatDatepickerModule, MatTooltipModule } from '@angular/material';
import { IboxModule } from 'app/components/common/ibox/ibox.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from "../tooltip/tooltip.module";

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    IboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    TooltipModule,
    MatTooltipModule
  ],
  declarations: [UserFormComponent],
  exports: [UserFormComponent]
})
export class UserFormModule { }
