import { Injectable } from "@angular/core";
import { UserType } from "../interfaces/baseinterface";
import { ActivatedRoute, Router } from "@angular/router";
import { Person } from "app/interfaces/person";

import { AppI18nService } from "app/services/app.i18n.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { SpinnerService } from "app/services/spinner.service";
import { environment } from "../../environments/environment";
import { AppService } from "./app.service";
import * as moment from "moment-timezone";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LoginService {

  public params: any = null;

  public urlReturn: string = "";

  public loggedIn() {
    return !!this.userInfo;
  }

  authenticate(username: string, password: string): Observable<any> {
    return Observable.create(observer => {
      // observer.next({
      //   user: {
      //     _id: "asd",
      //     landline: undefined,
      //     city: "",
      //     company: "asd",
      //     email: "asd@asd.com",
      //     type: UserType.client,
      //     country: "",
      //     name: "asd asd asd",
      //     mobilePhone: "",
      //     contacts: {
      //       emails: [],
      //       telephones: []
      //     },
      //     loggedAt: new Date()
      //   },
      //   tokenExpiration: moment.utc().add("days", 30).toDate()
      // })
      this.doPost("auth/signIn", { username: username.toLowerCase(), password: password }).subscribe(
        (res: any) => {
          console.log(res);
          if (!res.forcePasswordChange) {
            console.log(res);
            localStorage.setItem(this.appService.productKey + ".loggedUser", JSON.stringify(res));
          }
          observer.next(res);
          return;;
        },
        (err: any) => {
          observer.error(err);
        }
      );
    });
  }

  changePassword(userInfo: NewPasswordUser): Observable<any> {
    return Observable.create(observer => {
      this.isAuthenticated().subscribe(
        result => {
          let session: any = JSON.parse(localStorage.getItem(this.appService.productKey + ".loggedUser"));
          this.doPost("auth/changeUserPassword", { current_password: userInfo.existingPassword, password: userInfo.password },
            { headers: { Authorization: session.token } }).subscribe(
              data => { observer.next(data); return; },
              err => observer.error(err)
            );
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }

  forgotPassword(username: string): Observable<any> {
    return this.doPost("auth/forgotPassword", { username: username.toLowerCase() });
  }

  confirmNewPassword(email: string, verificationCode: string, password: string): Observable<any> {
    return this.doPost("auth/changePassword", { username: email.toLowerCase(), code: verificationCode, password: password });
  }

  logout(showMessage: boolean = true, redirect: boolean = true, returnUrl: boolean = false) {
    let session: any = JSON.parse(localStorage.getItem(this.appService.productKey + ".loggedUser"));
    this.spinner.activate();
    this.doPost("auth/signOut", {}, { headers: { Authorization: session.token } }).subscribe(
      (data) => {
        this.spinner.desactivate();
        this.userInfo = undefined;
        localStorage.removeItem(this.appService.productKey + ".loggedUser");

        if (showMessage) {
          this.alert.success(this.i18n.translate("general.logout.title"), this.i18n.translate("general.logout.text"));
        }
        if (redirect) {
          if (returnUrl) {
            let url = this.router.url;
            this.router.navigate(["/auth/login"], { queryParams: { returnUrl: encodeURI(url) } });
          } else {
            this.router.navigate(["/auth/login"]);
          }
        }
      },
      (err) => {
        console.log(err);
        this.spinner.desactivate();
        this.userInfo = undefined;
        localStorage.removeItem(this.appService.productKey + ".loggedUser");

        if (showMessage) {
          this.alert.success(this.i18n.translate("general.logout.title"), this.i18n.translate("general.logout.text"));
        }
        if (redirect) {
          if (returnUrl) {
            let url = this.router.url;
            this.router.navigate(["/auth/login"], { queryParams: { returnUrl: encodeURI(url) } });
          } else {
            this.router.navigate(["/auth/login"]);
          }
        }
      });
  }

  public setUserInfo(result) {
    if (result == null) this.userInfo = null;
    else {
      this.userInfo = {
        _id: result.user._id,
        landline: undefined,
        city: "",
        company: result.user.company,
        primaryEmail: result.user.email,
        type: <UserType>result.user.type,
        username: result.user.email,
        country: "",
        name: result.user.name,
        mobilePhone: "",
        contacts: {
          emails: [],
          telephones: []
        },
        loggedAt: new Date()
      };
    }
    return this.userInfo;
  }


  isAuthenticated(): Observable<boolean> {
    return Observable.create(observer => {
      let storage = localStorage.getItem(this.appService.productKey + ".loggedUser");
      if (!storage) {
        observer.error({ error: "User Session not found." });
        return;
      }
      let session: any = JSON.parse(storage);
      if (!this.userInfo) {
        this.setUserInfo(session);
      }

      if (moment.utc(session.tokenExpiration) > moment.utc()) {
        observer.next(true);
        return;
      } else {
        this.doPost("auth/refreshToken", { username: session.user.email, token: session.token }).subscribe(
          (res) => {
            session = Object.assign(session, res);
            localStorage.setItem(this.appService.productKey + ".loggedUser", JSON.stringify(session));
            observer.next(true);
            return;
          },
          (error) => {
            console.log(error);
            observer.error({ error: error });
            return;
          }
        );
      }
    });
  }

  constructor(private activatedRouter: ActivatedRoute,
    private alert: SweetalertService, private router: Router,
    private activatedRoute: ActivatedRoute, private i18n: AppI18nService, private spinner: SpinnerService,
    private appService: AppService, private http: HttpClient) { }

  public userInfo: UserInfo = undefined;

  // Use this method to do something to ALL post request, such as passing tokens/timestamps/headers
  protected doPost(url: string, body: any, options: any = {}): Observable<any> {
    return Observable.create(observer => {
      this.http.post(environment.apiUrl + url, body, options).subscribe(
        (t: any) => {
          observer.next(t);
          return;
        },
        error => {
          observer.error({ status: error.status, errors: error });
        }
      );
    });
  }

  protected doGet(url: string, options: any = {}): Observable<any> {
    return Observable.create(observer => {
      this.http.get(environment.apiUrl + url, options).subscribe(
        (t: any) => {
          observer.next(JSON.parse(t._body));
        },
        error => {
          observer.error({ status: error.status, errors: JSON.parse(error._body).errors });
        }
      );
    });
  }
}

export interface UserInfo extends Person {
  loggedAt: Date;
  idToken?: string;
  accessToken?: string;
  refreshToken?: string;
  type: UserType;
  company: string;
  username: string;
}

export class NewPasswordUser {
  username: string;
  existingPassword: string;
  password: string;
}

export class RegistrationUser {
  name: string;
  email: string;
  phone_number: string;
  password: string;
}
