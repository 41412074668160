import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from 'app/services/spinner.service';
import { LoginService } from 'app/services/login.service';
import { ContactService } from 'app/services/contact.service';
import { SweetAlert } from 'sweetalert/typings/core';
import { SweetalertService } from 'app/services/sweetalert.service';

@Component({
  selector: 'app-client-contact',
  templateUrl: './client-contact.component.html',
  styleUrls: ['./client-contact.component.css']
})
export class ClientContactComponent implements OnInit {

  public form = new FormGroup({
    masterKey: new FormControl("4Mj8um6LAkTpJFZmxHx79FwTCbD4vcQcwHcdvTMda6CyheC4qufb5kQ8rtwYwRjg", [Validators.required]),
    from: new FormControl("", [Validators.required]),
    subject: new FormControl("", [Validators.required]),
    message: new FormControl("", [Validators.required])
  });

  constructor(public spinner: SpinnerService, public loginService: LoginService, private contactService: ContactService, private alert: SweetalertService) { }

  ngOnInit() {
    this.form.get("from").setValue(this.loginService.userInfo.primaryEmail);

  }

  public onSubmit(){
    this.spinner.activate();
    this.contactService.create(this.form.getRawValue()).subscribe(x =>{
      this.spinner.desactivate();
      this.alert.success("Your message has been sent", "Thank you for contacting us!");
    })
  }

}
