<div class="wrapper wrapper-content">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-ibox>
          <h5 title>Keys Info</h5>
          <div content>
            <div class="row">
              <div class="col-md-4">
                <div class="widget style1 navy-bg">
                  <div class="row">
                    <div class="col-xs-4">
                      <i class="fas fa-key  fa-5x"></i>
                    </div>
                    <div class="col-xs-8 text-right">
                      <span> Number of Dark Sky Keys</span>
                      <h2 class="font-bold">{{keys}}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="widget style1 lazur-bg">
                  <div class="row">
                    <div class="col-xs-4">
                      <i class="fas  fa-umbrella fa-5x"></i>
                    </div>
                    <div class="col-xs-8 text-right">
                      <span> Maximum Components</span>
                      <h2 class="font-bold">{{maximum}}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="widget style1 yellow-bg">
                  <div class="row">
                    <div class="col-xs-4">
                      <i class="fas fa-umbrella fa-5x"></i>
                    </div>
                    <div class="col-xs-8 text-right">
                        <span> Available</span>
                      <h2 class="font-bold">{{available}}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-ibox>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-ibox>
          <h5 title>Your Dark Sky Keys &nbsp;<small> Each Dark Sky Key can be used with up to 40 FD Free Weather Modules.
              Key.</small></h5>
          <div content>
            <div class="row">
              <div class="col-md-12">
                <button (click)="openNew()" type="button" class=" pull-right btn btn-w-m btn-primary">
                  <i class="fas fa-plus"></i> New Dark Sky key
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mat-table-container">
                  <div class="mat-table-header">
                    <mat-form-field>
                      <input matInput (keyup)="applyFilter($event.target.value)"
                        placeholder="{{i18n.translate('general.labels.filter')}}">
                    </mat-form-field>
                  </div>
                  <mat-table #table [dataSource]="dataSource" matSort1>

                    <ng-container matColumnDef="email">
                      <mat-header-cell *matHeaderCellDef> E-mail Used For Dark Sky Key
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{ row.email }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="key">
                      <mat-header-cell class="number_cell" *matHeaderCellDef> View Key
                      </mat-header-cell>
                      <mat-cell class="number_cell" *matCellDef="let row">
                        <button class="btn btn-primary btn-xs" (click)="openIfo(row._id)">
                          <i class="fas fa-search"></i>
                        </button>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="position">
                      <mat-header-cell class="number_cell" *matHeaderCellDef> #
                      </mat-header-cell>
                      <mat-cell class="number_cell" *matCellDef="let row">
                        {{ row.position }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="components">
                      <mat-header-cell *matHeaderCellDef> Number of Components Associated with Key
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{ row.numComponents }} / 40
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="operations">
                      <mat-header-cell class="operation_cell" *matHeaderCellDef>
                      </mat-header-cell>
                      <mat-cell class="operation_cell" *matCellDef="let row">
                        <div style="margin-left: 10px;">
                          <button matTooltip="Edit the Dark Sky API Key and the E-mail." class="btn btn-success btn-xs"
                            (click)="openEdit(row._id)">
                            <i class="fas fa-wrench"></i>
                          </button>

                          <button type="button" style="margin-left: 5px;" class="pull-right btn btn-danger btn-xs"
                            matTooltip="Delete this Dark Sky key. You may not delete it if you have FD Keys using it."
                            (click)="delete(row._id)"><i class="fas fa-trash-alt"></i></button>
                        </div>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]="100"></mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </app-ibox>

        <div class="ibox ">
          <div class="ibox-content">
            <div content>
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-2">
                  <img id="darksky" src="assets/images/darksky.png">
                </div>
                <div class="col-md-5">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>