import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { APIKey } from 'app/interfaces/apikey';
import { Observable } from 'rxjs';

@Injectable()
export class APIKeysService extends BaseService<APIKey>{
    rootUrl = "apiKeys";

    public moduleLink(): Observable<string> {
        return Observable.create(observer => {
            this.doGet("moduleURL").subscribe(res => {
                observer.next(res.data);
                return;
            })
        })
    }

    public getGrid(): Observable<string> {
        return this.doGet(this.rootUrl + "?csv=1");
    }
}