<div class="wrapper wrapper-content">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-ibox>
          <h5 title>Keys Info</h5>
          <div content>
            <div class="row">
              <div class="col-md-4">
                <div class="widget style1 navy-bg">
                  <div class="row">
                    <div class="col-xs-4">
                      <i class="fas fa-key  fa-5x"></i>
                    </div>
                    <div class="col-xs-8 text-right">
                      <span> Number of Keys</span>
                      <h2 class="font-bold">{{keys}}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="widget style1 lazur-bg">
                  <div class="row">
                    <div class="col-xs-4">
                      <i class="fas  fa-umbrella fa-5x"></i>
                    </div>
                    <div class="col-xs-8 text-right">
                      <span> Maximum Components</span>
                      <h2 class="font-bold">{{maximum}}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="widget style1 yellow-bg">
                  <div class="row">
                    <div class="col-xs-4">
                      <i class="fas fa-umbrella fa-5x"></i>
                    </div>
                    <div class="col-xs-8 text-right">
                      <span> Available</span>
                      <h2 class="font-bold">{{available}}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-ibox>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-ibox>
          <h5 title>FD API Keys &nbsp; <small>Each Key is locked to a unique N4 HostID.</small>
          </h5>
          <div content>
            <div class="row">
              <div class="col-md-12">
                <a class="pull-right btn btn-w-m btn-success" (click)="downloadCSV()" style="margin-left: 15px;">
                  <i class="fas fa-save"></i> Download as csv
                </a>
                <a routerLink="new" class=" pull-right btn btn-w-m btn-primary">
                  <i class="fas fa-plus"></i> Add Weather Module
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mat-table-container">
                  <div class="mat-table-header">
                    <mat-form-field>
                      <input matInput (keyup)="applyFilter($event.target.value)"
                        placeholder="{{i18n.translate('general.labels.filter')}}">
                    </mat-form-field>
                  </div>
                  <mat-table #table [dataSource]="dataSource" matSort1>

                    <ng-container matColumnDef="name">
                      <mat-header-cell class="name-cell" *matHeaderCellDef> Friendly Name
                      </mat-header-cell>
                      <mat-cell class="name-cell" *matCellDef="let row">
                        {{ row.name }} &nbsp; <div *ngIf="row.note" matTooltip="{{row.note}}"
                          matTooltipPosition="above"> <i class=" far fa-sticky-note"></i>
                        </div>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="hostid">
                      <mat-header-cell class="hostid-cell" *matHeaderCellDef> HostID
                      </mat-header-cell>
                      <mat-cell class="hostid-cell" *matCellDef="let row">
                        <ng-container *ngIf="row.hostId">
                          {{row.hostId}}
                        </ng-container>
                        <ng-container *ngIf="!row.hostId">
                          Module Not Yet Run
                        </ng-container>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="operations">
                      <mat-header-cell class="operation_cell" *matHeaderCellDef>
                      </mat-header-cell>
                      <mat-cell class="operation_cell" *matCellDef="let row">
                        <div style="margin-left: 10px;">
                          <a class="btn btn-success btn-xs" matTooltip="Change weather location or friendly name."
                            matTooltipPosition="above" routerLink="/weather/keys/{{row._id}}">
                            <i class="fas fa-wrench"></i>
                          </a>

                          <button type="button"
                            matTooltip="Delete this key and free one slot for another FD Free Weather Module Key."
                            matTooltipPosition="above" style="margin-left: 5px;"
                            class="pull-right btn btn-danger btn-xs" (click)="delete(row._id)"><i
                              class="fas fa-trash-alt"></i></button>
                        </div>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="key">
                      <mat-header-cell class="api-cell" *matHeaderCellDef>FD Free Weather Module Key
                      </mat-header-cell>
                      <mat-cell class="api-cell" *matCellDef="let row">
                        {{ row.apiKey }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="location">
                      <mat-header-cell *matHeaderCellDef> Weather Location
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <a (click)="showMap(row._id)">
                          {{ row.location }}
                        </a>

                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]="100"></mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </app-ibox>
      </div>
    </div>
  </div>
</div>