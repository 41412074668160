import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyFormComponent } from './company-form.component';
import { MatOptionModule, MatSelectModule, MatInputModule, MatFormFieldModule } from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { IboxModule } from 'app/components/common/ibox/ibox.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    IboxModule,
    FormsModule,
    MatDatepickerModule,
    ReactiveFormsModule
  ],
  declarations: [CompanyFormComponent],
  exports: [CompanyFormComponent]
})
export class CompanyFormModule { }
