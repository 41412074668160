import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { SpinnerService } from 'app/services/spinner.service';
import { LoginService } from 'app/services/login.service';
import { User } from 'app/interfaces/user';
import { UserType } from 'app/interfaces/baseinterface';
import { AppI18nService } from 'app/services/app.i18n.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import { IReturnedError } from 'app/services/base.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private router: Router, private i18n: AppI18nService,private alert: SweetalertService, private userService: UserService, private spinnerService: SpinnerService, private loginService: LoginService) { }

  public user: User;
  private originalUser: User;

  ngOnInit() {
    this.spinnerService.activate();
    console.log(this.loginService.userInfo);
    this.userService.getOne(this.loginService.userInfo._id).subscribe(x => {
      this.spinnerService.desactivate();
      this.originalUser = x.data;
      this.user = x.data;
    })
  }

  public save(event) {
    let user: User = {
      _id: this.originalUser._id,
      company: event.getRawValue().company,
      password: event.getRawValue().password,
      email: event.getRawValue().primaryEmail,
      person: {
        city: event.getRawValue().city,
        country: event.getRawValue().country,
        // landline: event.getRawValue().mobile,
        // contacts: { emails: event.getRawValue().person.emails.split(","), telephones: event.getRawValue().person.telephones.split(',') },
        primaryEmail: event.getRawValue().primaryEmail,
        name: event.getRawValue().name,
        mobilePhone: event.getRawValue().mobilephone
      },
      type: 0,
      username: event.getRawValue().username,
    }

    console.log(event.getRawValue());

    this.spinnerService.activate();
    this.userService.update(this.originalUser, user).subscribe(
      res => {
        this.spinnerService.desactivate();
        this.alert.success("Success","Changes on your user have been saved.");
        this.router.navigate(["/weather"]);
      },
      err => {
        let errors: IReturnedError = {
          errors: []
        }

        for (let x of err.errors) {
          errors.errors.push({ field: "", message: x });
        }

        this.spinnerService.desactivate();
        this.alert.error(this.i18n.translate("general.messages.error"), this.i18n.translate("general.messages.invalidForm", this.userService.generateHtmlListOfErrors(errors)));
      });
  }

}
