import { Component, OnInit } from "@angular/core";
import { AppI18nService } from "../../../services/app.i18n.service";
import { AppService } from "app/services/app.service";
import { Subscription ,  Observable } from "rxjs";
import 'rxjs/add/observable/timer';

@Component( {
  selector: "app-footer",
  templateUrl: "footer.template.html"
} )
export class FooterComponent implements OnInit {
  ngOnInit(): void {
    let remainingTime = new Date().getSeconds() -
      new Date( new Date( new Date().setMinutes( new Date().getMinutes() + 1 ) ).setSeconds( 0 ) ).getSeconds();
    remainingTime *= 1000;

    this.timerObservable = Observable.timer( remainingTime, 60000 ).subscribe( t => {
      this.currentDate = new Date();
    } );
  }

  public timerObservable: Subscription;
  public currentDate: Date = new Date();
  public currentYear: number = new Date().getFullYear();

  constructor( public i18n: AppI18nService, public appService: AppService ) {
  }

}
