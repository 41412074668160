
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { BaseService, IServiceError } from "app/services/base.service";
import { IBuilding } from "app/interfaces/building";
import { UserType } from "app/interfaces/baseinterface";

@Injectable()
export class BuildingService extends BaseService<IBuilding> {
  rootUrl = "buildings";

  public checkForCredentials() {
    return super.checkForCredentials(UserType.admin);
  }

  private checkAll(building: IBuilding) {
    let errors: IServiceError[] = [];

    if (building.company === "") {
      errors.push({ field: "company", message: "This building needs to belong to a valid company" });
    }

    if (building.address === undefined) {
      errors.push({ field: "address", message: "This building needs a valid address" });
    }

    if (building.name === "") {
      errors.push({ field: "name", message: "This building needs a valid name" });
    }

    if (building.description === "") {
      errors.push({ field: "description", message: "This building needs a valid description" });
    }

    if ((building.contacts.emails === undefined || building.contacts.emails.length === 0) &&
      (building.contacts.telephones === undefined || building.contacts.telephones.length === 0)) {
      errors.push({ field: "contacts", message: "This building needs at least one valid contact information" });
    }

    if (errors.length > 0) {
      return observableThrowError({ status: 400, errors: errors });
    }

    return undefined;
  }

  public create(building: IBuilding) {
    let err = this.checkForCredentials();
    if (err) {
      return err;
    } else {
      err = this.checkAll(building);
    }

    return super.create(building);
  }

  public update(oldBuilding: IBuilding, building: IBuilding) {
    let err = this.checkForCredentials();
    if (err) {
      return err;
    } else {
      err = this.checkAll(building);
    }

    return err ? err : super.update(oldBuilding, building);
  }

  public delete(uuid: string) {
    const err = this.checkForCredentials();
    return err ? err : super.delete(uuid);
  }

  public getNewDevices(building: string, unAuthOnly: boolean) {
    return super.doGet("buildings/" + building + "/newdevices" + (unAuthOnly ? "?unauth=true" : "")).
      map(response => response);
  }

  public setNewDeviceAuth(building: string, device: string, rejected: boolean) {
    return super.doPost("buildings/" + building + "/device/" + device + "/validation", { reject: rejected }).
      map(response => response);
  }

  public GetMeterReadings(building, fromDate, toDate) {
    let onlyProcessed = 0, onlyVirtual = 0, onlyLiveData = 0; // TODO: use filter in the back-end to load less data
    const err = super.checkForCredentials(UserType.admin, UserType.employee);
    return err ? err :
      super.doGet("mobileMetering/meterReadingsForBuilding/" + building + "?onlyProcessed=" + onlyProcessed + "&onlyVirtual=" +
        onlyVirtual + "&onlyLiveData=" + onlyLiveData + "&from=" + fromDate + "&to=" + toDate).map(response => response);
  }

  public GetMeterReadingImage(id) {
    const err = super.checkForCredentials(UserType.admin, UserType.employee);
    return err ? err :
      super.doGet("mobileMetering/download/" + id).map(response => response);
  }

  public SaveMeterReadings(id, reading) {
    const err = super.checkForCredentials(UserType.admin);
    return err ? err :
      super.doPost("mobileMetering/" + id + "/reading", { meter_reading: reading }).map(response => response);
  }

  public validateMeterReadings(id) {
    const err = super.checkForCredentials(UserType.admin);
    return err ? err :
      super.doPost("mobileMetering/" + id + "/validate", {}).map(response => response);
  }
}
