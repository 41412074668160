import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AppService } from "app/services/app.service";
import { AppI18nService } from "app/services/app.i18n.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LoginService } from "app/services/login.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { SpinnerService } from "../../../services/spinner.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component( {
  selector: "app-recovery",
  templateUrl: "./recovery.component.html",
  styleUrls: [ "./recovery.component.css" ],
  encapsulation: ViewEncapsulation.None
} )
export class RecoveryComponent implements OnInit {

  constructor( private route: ActivatedRoute, private router: Router, private spinner: SpinnerService,
    private loginService: LoginService, public appService: AppService, public i18n: AppI18nService, private alert: SweetalertService ) {
    this.route.queryParams.subscribe( query => {
      if( query[ "username" ] ) {
        this.usernameForm = new FormGroup( {
          username: new FormControl( query[ "username" ], Validators.required )
        } );
        this.typingUsername = false;
        if( query[ "code" ] ) {
          this.verifyForm = new FormGroup( {
            newPassword: new FormControl( "", [ Validators.required ] ),
            newPasswordConfirmation: new FormControl( "", [ Validators.required, validatePassword ] ),
            code: new FormControl( query[ "code" ], Validators.required )
          } );
        }
      }
    } );
  }

  public typingUsername: boolean = true;

  public usernameForm: FormGroup = new FormGroup( {
    username: new FormControl( "", Validators.required )
  } );

  public verifyForm: FormGroup = new FormGroup( {
    newPassword: new FormControl( "", [ Validators.required ] ),
    newPasswordConfirmation: new FormControl( "", [ Validators.required, validatePassword ] ),
    code: new FormControl( "", Validators.required )
  } );

  public onSumitUsername() {
    let that = this;
    this.spinner.activate();
    this.loginService.forgotPassword( this.usernameForm.value.username ).subscribe(
      ( result ) => {
        that.spinner.desactivate();
        that.alert.info( that.i18n.translate( "general.warnings.warning" ), that.i18n.translate( "general.warnings.verifyCode" ) );
        that.typingUsername = false;
      },
      ( error ) => {
        console.error( error );
        that.spinner.desactivate();
        that.alert.info( that.i18n.translate( "general.warnings.warning" ), that.i18n.translate( "general.warnings.verifyCode" ) );
        that.typingUsername = false;
      }
    );
  }

  ngOnInit() {
    this.verifyForm.controls[ "newPassword" ].valueChanges.subscribe!( {
      next: ( value ) => {
        this.verifyForm.controls[ "newPasswordConfirmation" ].updateValueAndValidity();
      }
    } );
  }

  public onSubmitPassword() {
    this.spinner.activate();
    let that = this;
    this.loginService.confirmNewPassword( this.usernameForm.value.username, this.verifyForm.value.code, this.verifyForm.value.newPassword ).
         subscribe(
           ( res ) => {
             that.spinner.desactivate();
             that.alert.success( that.i18n.translate( "general.messages.success" ),
               that.i18n.translate( "general.messages.passwordReseted" ) );
             that.router.navigate( [ "/auth/login" ] );
           },
           ( error ) => {
             console.error( error );
             that.spinner.desactivate();
             that.alert.info( that.i18n.translate( "general.messages.error" ), that.i18n.translate( "tooltips.general.password" ) );
           } );
  }
}

export function validatePassword( f: FormControl ) {
  if( f.parent ) {
    return f.parent.controls[ "newPassword" ].value == f.parent.controls[ "newPasswordConfirmation" ].value ?
           null :
      { validatePassword: { valid: false } };
  }
  return undefined;
}
