import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { AppI18nService } from 'app/services/app.i18n.service';
import { ClientDarkskyKeysCreateComponent } from './client-darksky-keys-create/client-darksky-keys-create.component';
import { SpinnerService } from 'app/services/spinner.service';
import { DarkSkyService } from 'app/services/dark.sky.service';
import { ClientDarkskyKeysEditComponent } from './client-darksky-keys-edit/client-darksky-keys-edit.component';
import { SweetalertService } from 'app/services/sweetalert.service';
import { ClientDarkskyKeysInfoComponent } from './client-darksky-keys-info/client-darksky-keys-info.component';

@Component({
  selector: 'app-client-darksky-keys',
  templateUrl: './client-darksky-keys.component.html',
  styleUrls: ['./client-darksky-keys.component.css']
})
export class ClientDarkskyKeysComponent implements OnInit {

  public dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public keys: number = 0;
  public maximum: number = 0;
  public available: number = 0;


  public displayedColumns = ["position", "key", "email", "components", "operations"];

  constructor(public i18n: AppI18nService, public dialog: MatDialog, private spinner: SpinnerService, private darkSkyService: DarkSkyService, private alert: SweetalertService) { }

  ngOnInit() {
    this.refresh();
  }

  private refresh() {
    this.spinner.activate();
    this.darkSkyService.getAll().subscribe(x => {
      this.spinner.desactivate();

      let t = x.data;

      this.keys = x.weatherKeys;
      this.maximum = x.MaxComponents;
      this.available = x.AvailableComponents;

      for (var i = 0; i < t.length; i++) {
        t[i]['position'] = i + 1;
      }

      this.dataSource.data = t;
    })
  }

  public openIfo(id) {
    let t: any = this.dataSource.data.find((x: any) => x._id == id);
    const dialogRef = this.dialog.open(ClientDarkskyKeysInfoComponent, {
      width: '400px',
      data: { isValid: false, key: t.apiKey, email: t.email }
    });
  }


  public openNew() {
    const dialogRef = this.dialog.open(ClientDarkskyKeysCreateComponent, {
      width: '750px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.refresh();
      }
    })
  }

  public openEdit(id) {
    const dialogRef = this.dialog.open(ClientDarkskyKeysEditComponent, {
      width: '750px',
      data: { id: id }
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.refresh();
      }
    })
  }

  public delete(id) {
    this.alert.custom({
      title: "Warning", text: "Do you want to delete this Dark Sky API Key? This is an operation with no return.", buttons: {
        yes: {
          text: "Yes",
          value: "yes"
        },
        no: {
          text: "No",
          value: "no"
        },
      },
      icon: "warning",

    }).then(x => {
      if (x == "cancel") {

      }
      else if (x == "yes") {
        this.spinner.activate();
        this.darkSkyService.delete(id).subscribe(x => {
          this.spinner.desactivate();
          this.refresh();
        })
      }
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
